import React from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../../../global/components/error_pages/PageNotFound";
import CurrentProjects from "./CurrentProjects";
import ViewProject from "./ViewProject";

export default function Projects(props) {
  return (
    <Switch>
      <Route path="/current-projects/:project">
        <ViewProject />
      </Route>
      <Route path="/current-projects">
        <CurrentProjects />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}
