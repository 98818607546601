import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import clsx from "clsx";
import { ArrowForward } from "@material-ui/icons";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import ApiRequest from "../../../api/ApiRequest";
import { BLG_API_GET_BLOG_POSTS } from "../../../api/apiThreads";
import { brown, purple } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: brown[200],
    backgroundImage: "url('./images/intro-slider/programs.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff!important",
  },
  faint: {
    opacity: "0.7",
  },
  slideImage: {
    width: "100%",
    height: "auto",
    minHeight: "200px",
    objectFit: "cover",
    objectPosition: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  paper: {
    height: "100%",
  },
  mask: {
    backgroundColor: "rgba(0,0,0,0.4)",
    height: "100%",
    color: "#fff",
  },
  programTitle: {
    color: "#5C635F",
    fontSize: "25px",
    fontWeight: 700,
    textDecoration: "none",
  },
  root: {
    background: "#f0f6f1",
  },
}));
const programsList = [
  {
    title: "Financial Clubs",
    blurb: `We strive to build stronger, more unified families through
                  biblical teaching with practical applications. Our teachings
                  help our members to learn self-respect and self-worth that is
                  given to them by God, and can be passed on to their
                  families.`,
    image: "./images/fc.JPG",
  },
  {
    image: "./images/bridge-programe.jpg",
    title: "Base Ministries",
    blurb: `We strive to provide outreach programs for our local village members that will improve their lives, through building stronger communities  and providing educational opportunities that help raise people out of poverty. `,
  },
  {
    image: "./images/cp-farming.png",
    title: "Farming Program",
    blurb: `The vast majority of our rural member use farming as their sole source of income.  We realize that by helping to improve their farming methods and techniques we can improve their ability to feed their families and increase their income through the surplus.`,
  },
];

export default function ProgramsMain(props) {
  const classes = useStyles();
  const [apiQueryState, setApiQueryState] = useState({
    blog_name: "PROGRAMS",
  });
  return (
    <div class={classes.root}>
      <Grid container className={classes.header}>
        <Grid item xs={12}>
          <Container>
            <Grid
              container
              spacing={3}
              alignItems="center"
              className="pt-5 pb-3"
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h2" color="inherit">
                  Programs
                </Typography>
                <Typography className="pt-2 pb-5">
                  Check out our amazing programs which have created alot of
                  change in people's lives. #BlessedToBeABlessing
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Container className="pt-5 pb-5">
        <Grid spacing={3} container className="mt-3">
          <ApiRequest
            query={apiQueryState}
            autoload
            thread={BLG_API_GET_BLOG_POSTS}
          >
            {({
              res,
              error,
              loading,
              ErrorView,
              RefreshButton,
              loadingPage,
              nextPage,
            }) => {
              if (loading) {
                return (
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <CircularProgress />
                  </div>
                );
              }

              if (error) {
                return <ErrorView />;
              }

              let programs = null;
              if (typeof res === "object") {
                if (typeof res.data === "object") {
                  if (Array.isArray(res.data.posts)) {
                    programs = res.data.posts;
                  }
                }
              }

              if (programs == null) {
                return (
                  <div>
                    <Typography>
                      Failed to load programs. Please try again after some time.
                    </Typography>
                    <RefreshButton variant="icon" />
                  </div>
                );
              }
              return programs.map((program, index) => {
                return (
                  <Grid item xs={12} md={4} key={index}>
                    <Paper
                      variant="outlined"
                      className={clsx(classes.paper, "p-3")}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${program.image})`,
                        }}
                        className={classes.slideImage}
                      ></div>
                      <Link
                        className={classes.programTitle}
                        variant="h3"
                        to={`/programs/${program.post}-${program.title
                          .replaceAll(" ", "_")
                          .toLowerCase()}`}
                      >
                        {program.title}
                      </Link>
                      <Typography>{program.summary}</Typography>
                      <Button
                        component={Link}
                        to={`/programs/${program.post}-${program.title
                          .replaceAll(" ", "_")
                          .toLowerCase()}`}
                        variant="contained"
                        endIcon={<ArrowForward />}
                      >
                        Learn More
                      </Button>
                    </Paper>
                  </Grid>
                );
              });
            }}
          </ApiRequest>
        </Grid>
      </Container>
    </div>
  );
}
