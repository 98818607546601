import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Favorite } from "@material-ui/icons";
import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { KijjeInput } from "../../../global/components/inputs/Inputs";
import clsx from "clsx";
import ApiRequest from "../../../api/ApiRequest";
import { SEND_MESSAGE } from "../../../api/apiThreads";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  bg1: {
    backgroundImage: "url('/images/knowledge-base/bg1.jpg')",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
  heading1: {
    fontSize: "40px",
    fontFamily: "Poppins,sans-serif",
    lineHeight: "34px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  titleHeading: {
    marginTop: 0,
    marginBottom: "25px",
    fontFamily: "Poppins,sans-serif",
    fontSize: "24px",
    lineHeight: "34px",
    fontWeight: "700",
  },
  paper: {
    padding: theme.spacing(3),
    border: "solid 1px rgb(200, 81, 165)",
    transition:
      "box-shadow 0.3s ease 0s, transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s",
    boxShadow:
      "rgba(200, 81, 165, 0.28) 0px 4px 20px -4px, rgba(0, 176, 202, 0.28) 0px 6px 20px -6px",
    ["&:hover"]: {
      boxShadow:
        "rgba(200, 81, 165, 0.82) 0px 4px 20px -4px, rgba(0, 176, 202, 0.87) 0px 6px 20px -6px",
    },
  },
  titleText: {
    marginTop: "0px",
    marginBottom: "5px",
    fontFamily: "Poppins, sans-serif",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "600",
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    width: "40%",
    marginTop: "20px",
    marginBottom: "20px",
    height: "2px",
  },
}));

export default function ContactMain(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Switch>
        <Route path="/how-to-donate"></Route>
        <Route>
          <div className={clsx(classes.contactUs, "py-5 pt-0")}>
            <div className={classes.contactUsBg}>
              <Grid container className={classes.bg1}>
                <Grid item xs={12}>
                  <Container>
                    <Grid container spacing={3}>
                      <Grid item xs={10} md={5} className="mt-5 pt-5 pb-5">
                        <Typography className="mb-2">Contact Us</Typography>
                        <Typography variant="h1" className={classes.heading1}>
                          Reach out to us
                        </Typography>
                      </Grid>
                      <Grid item xs={10} md={5} className=""></Grid>
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
              <Container>
                <Grid container spacing={3} justify="space-between">
                  <ApiRequest
                    thread={SEND_MESSAGE}
                    initialPayload={{
                      message: "",
                      email: "",
                      subject: "",
                      first_name: "",
                      last_name: "",
                    }}
                  >
                    {({
                      loading,
                      error,
                      ErrorView,
                      input,
                      SubmitButton,
                      res,
                    }) => {
                      if (typeof res == "object") {
                        if (typeof res.data == "object") {
                          if (typeof res.data.sent == "boolean") {
                            if (res.data.sent == true) {
                              return (
                                <Grid item xs={12} md={5}>
                                  <Alert>
                                    <AlertTitle>
                                      Your message has been sent
                                    </AlertTitle>
                                    <Typography>
                                      Our team has received your message. If you
                                      have any inquires we shall get back you.
                                    </Typography>
                                  </Alert>
                                </Grid>
                              );
                            }
                          }
                        }
                      }
                      return (
                        <Grid item xs={12} md={5}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <KijjeInput
                                label="FIRST NAME*"
                                labelVariant="small"
                                variant="filled"
                                labelVariant="small"
                                placeholder="John"
                                {...input({ name: "first_name" })}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <KijjeInput
                                label="LAST NAME*"
                                variant="filled"
                                labelVariant="small"
                                placeholder="Doe"
                                {...input({ name: "last_name" })}
                              />
                            </Grid>
                          </Grid>
                          <KijjeInput
                            label="Subject*"
                            variant="filled"
                            labelVariant="small"
                            placeholder="How would you like us to help you."
                            {...input({ name: "subject" })}
                          />
                          <KijjeInput
                            label="EMAIL*"
                            variant="filled"
                            labelVariant="small"
                            placeholder="john-doe@example-mail.com"
                            {...input({ name: "email" })}
                          />
                          <KijjeInput
                            label="MESSAGE*"
                            placeholder="Write a message here"
                            variant="filled"
                            labelVariant="small"
                            multiline
                            {...input({ name: "message" })}
                          />
                          <SubmitButton
                            className="mt-4"
                            variant="contained"
                            color="secondary"
                          >
                            CONTACT OUR TEAM
                          </SubmitButton>
                        </Grid>
                      );
                    }}
                  </ApiRequest>
                  <Grid item xs={12} md={5}>
                    <Paper className={classes.paper}>
                      <Typography variant="h3" color="primary">
                        How to donate
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: 300, fontSize: "1rem" }}
                      >
                        To donate to Abundant Blessing Ministries, and receive a
                        US tax deductible receipt, contact Good Measure and note
                        that your donation is for Abundant Blessing Ministries.
                      </Typography>
                      <Button
                        startIcon={<Favorite />}
                        variant="contained"
                        className="mt-3"
                        component="a"
                        target="blank"
                        href="https://pushpay.com/g/goodmeasure"
                      >
                        Start Now
                      </Button>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </Route>
      </Switch>
    </React.Fragment>
  );
}
