import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";
import clsx from "clsx";
import React, { useState } from "react";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";
import { NavLink } from "react-router-dom";
import ApiRequest from "../../../api/ApiRequest";
import { BLG_API_GET_BLOG_POSTS } from "../../../api/apiThreads";
import { LoadingMorePosts } from "../Blog/BlogMain";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.boxShadows[3],
    borderRadius: "5px",
    height: "100%",
  },
  header: {
    width: "100%",
    backgroundColor: orange[300],
    backgroundImage: "url('./images/intro-slider/projects.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff!important",
  },
  root: {
    width: "100%",
    background: grey[100],
  },
  image: {
    width: "100%",
    height: "150px",
    objectFit: "cover",
    objectPosition: "top",
  },
  divider: {
    backgroundColor: "#000",
  },
  titleLink: {
    textDecoration: "none!important",
  },
}));

export default function CurrentProjects(props) {
  const classes = useStyles();
  const [apiQueryState, setApiQueryState] = useState({
    blog_name: "CURRENT_PROJECTS",
  });
  const projects = [
    {
      title: "Book making",
      summary: `
      We teach club members how to make books which they can sell to people and schools in the community and earn money. This has helped club unemployed club members have a source of income. 
      `,
      image: "./images/cp-book-making.jpg",
    },
    {
      title: "Soap Making Project",
      summary:
        "The soap that we train our students to make is sold to many families daily. It is used for washing clothing, houses, dishes, hair and bodies.",
      image: "./images/soap-project.jpg",
    },
    {
      title: "Candle Making Project",
      summary:
        "Candles are purchased daily in areas where electricity has not yet reached. For about 20 cents a family can have light after the sun goes down.",
      image: "./images/candle-making/",
    },
    {
      title: "Hair Braiding",
      summary:
        "Most women are willing to sacrifice to afford to get their hair braided. Hair braiding is a marketable skill even in the poorest communities. ABM teaches a 10 week class on hair care and braiding which gives students a skill marketable for a lifetime.",
      image: "",
    },

    {
      title: "Goat Project",
      summary: `
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus, rerum excepturi deserunt dolorem hic sapiente soluta atque dolores inventore numquam, alias officiis non suscipit voluptas illum asperiores vitae sed harum!
      `,
      image: "",
    },
    {
      title: "Farmer's club",
      summary: ` Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus, rerum excepturi deserunt dolorem hic sapiente soluta atque dolores inventore numquam, alias officiis non suscipit voluptas illum asperiores vitae sed harum!`,
      image: "",
    },
    {
      title: "Demonstration farm",
      summary: `
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus, rerum excepturi deserunt dolorem hic sapiente soluta atque dolores inventore numquam, alias officiis non suscipit voluptas illum asperiores vitae sed harum!
      `,
      image: "",
    },
    {
      title: "Introducing Quality Seed",
      summary: `
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus, rerum excepturi deserunt dolorem hic sapiente soluta atque dolores inventore numquam, alias officiis non suscipit voluptas illum asperiores vitae sed harum!
      `,
      image: "",
    },
  ];
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Container className="py-5">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" color="inherit">
                Current Projects
              </Typography>
              <Typography>
                Check out our current projects which we are running. <br /> Each
                project is carried out under our programs.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container className={clsx("py-5")}>
        <Grid container spacing={3}>
          <ApiRequest
            thread={BLG_API_GET_BLOG_POSTS}
            query={apiQueryState}
            autoload
            addOnThread={JSON.stringify(apiQueryState)}
            dataKey="posts"
            limit={20}
            page={1}
            hasPagination={true}
          >
            {({
              res,
              error,
              loading,
              ErrorView,
              RefreshButton,
              loadingPage,
              nextPage,
            }) => {
              if (loading === true) {
                return <CircularProgress />;
              }

              if (error) {
                return <ErrorView />;
              }
              let posts = [];
              let total = 0;
              if (typeof res == "object") {
                if (Array.isArray(res.posts)) {
                  console.log(res.data.posts);
                  posts = res.posts;
                }

                if (typeof res.data == "object") {
                  total = res.data.total;
                }
              }

              return (
                <React.Fragment>
                  {posts.map((post, index) => {
                    return (
                      <LazyLoadComponent key={index}>
                        <ProjectCard {...post} />
                      </LazyLoadComponent>
                    );
                  })}
                  {loadingPage && <LoadingMorePosts />}

                  {loadingPage === false && posts.length < total ? (
                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          nextPage();
                        }}
                      >
                        Load More
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            }}
          </ApiRequest>
        </Grid>
      </Container>
    </div>
  );
}

function ProjectCard(props) {
  const classes = useStyles();
  let { summary, title, image } = props;
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper className={classes.paper}>
        <div
          className={clsx(
            classes.header,
            "d-flex justify-content-between align-items-center"
          )}
        >
          <LazyLoadImage src={image} className={classes.image} />
        </div>
        <div className="p-3">
          <Typography
            variant="h6"
            className={clsx("my-2", classes.titleLink)}
            component={NavLink}
            to={`/current-projects/${props.post}-${title
              .replaceAll(" ", "_")
              .toLowerCase()}`}
          >
            {title}
          </Typography>
          <Typography>
            {summary.slice(0, 80)}
            ...
            <Button
              color="primary"
              component={NavLink}
              to={`/current-projects/${props.post}-${title
                .replaceAll(" ", "_")
                .toLowerCase()}`}
              size="small"
              className="me-2"
            >
              Read More
            </Button>
          </Typography>
        </div>
      </Paper>
    </Grid>
  );
}
