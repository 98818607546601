import React from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { LazyLoadImage } from "react-lazy-load-image-component";
import clsx from "clsx";
import ApiRequest from "../../../api/ApiRequest";
import { BLG_API_SHOW_BLOG_POST } from "../../../api/apiThreads";
import { NavLink, useParams } from "react-router-dom";
import HtmlParser from "react-html-parser";
import {
  ArrowBack,
  ArrowForward,
  CardGiftcardOutlined,
  Home,
  KeyboardArrowRight,
  NewReleases,
  PostAdd,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  footer: {
    backgroundColor: blueGrey[50],
    minHeight: "200px",
  },
  postBodyRoot: {
    paddingTop: "30px",
    paddingBottom: "50px",
    backgroundColor: theme.bgColor,
  },
  postBody: {
    ...theme.typography.body1,
    "& h1": {
      ...theme.typography.h1,
    },
    "& h2": {
      ...theme.typography.h2,
    },
    "& h3": {
      ...theme.typography.h3,
    },
  },
  postImage: {
    width: "100%",
    maxHeight: "250px",
    borderRadius: "10px",
  },
}));

export default function ViewBlogPost(props) {
  const classes = useStyles();
  let { post_id } = useParams();
  let blog_name = props.blog_name || "RESEARCH_POSTS";
  if (props.post_id > 0) {
    post_id = props.post_id;
    blog_name = props.blog_name;
  }
  return (
    <div className={clsx("py-5", classes.root)}>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <Container>
            {props.hideNextPrevBtns === true ? (
              ""
            ) : (
              <BreadCrumb blog_name={blog_name} />
            )}
            <Grid
              container
              spacing={3}
              justify="space-between"
              alignItems="center"
              className="py-3"
            >
              {props.hideNextPrevBtns === true ? (
                ""
              ) : (
                <Grid item xs={12} md={12}>
                  <div>
                    <NextPrevButtons blog_name={blog_name} />
                  </div>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <ApiRequest
                  autoload
                  query={{
                    blog_name,
                  }}
                  params={{ post_id }}
                  thread={BLG_API_SHOW_BLOG_POST}
                  addOnThread={post_id}
                >
                  {({ res, loading, error, ErrorView, RefreshButton }) => {
                    if (loading) {
                      return <CircularProgress />;
                    }

                    if (error) {
                      return (
                        <div>
                          <ErrorView />
                          <RefreshButton variant="icon" />
                        </div>
                      );
                    }

                    let post = "";
                    if (typeof res === "object") {
                      if (typeof res.data === "object") {
                        if (typeof res.data.post == "object") {
                          post = res.data.post;
                        }
                      }
                    }
                    return (
                      <div>
                        {/* <RefreshButton variant="icon" /> */}
                        {props.hidePostTitle == true ? (
                          ""
                        ) : (
                          <Typography className="mb-4" variant="h3">
                            {post.title}
                          </Typography>
                        )}
                        <PostRenderer {...post} />
                      </div>
                    );
                  }}
                </ApiRequest>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      {props.hideNextPrevBtns === true ? (
        ""
      ) : (
        <Grid className={classes.footer} container>
          <Grid item xs={12}>
            <Container>
              <div className="my-5">
                <Grid item xs={12} md={12}>
                  <NextPrevButtons blog_name={blog_name} />
                </Grid>
              </div>
            </Container>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

/**
 * BLOG POST EDITOR VIEWER FOR VIEWING
 * THE FIRST VERSION BLOG POSTS AND THE
 * SECOND VERSION
 * BLOG POSTS.
 */
function PostRenderer(props) {
  const renderSection = (section, index) => {
    switch (section.type) {
      case "paragraph":
        if (section.children.length == 0) {
          return <Typography paragraph>{section.textContent}</Typography>;
        }
        return section.children.map((child, childIndex) =>
          this.renderSection(child, index + "-" + childIndex)
        );

      // render the image
      case "image":
        let { src, height, width, objectFit } = section;

        if (typeof src == "undefined") {
          src = "";
        }
        return (
          <div className="w-100 ">
            <div className="image-wrapper">
              {src.length > 0 ? (
                <LazyLoadImage style={{ height, width, objectFit }} src={src} />
              ) : (
                <div className="no-image-src d-flex justify-content-center align-items-center">
                  <i className="bi bi-image"></i>
                </div>
              )}
            </div>
          </div>
        );

      case "video":
        break;

      case "grid":
        break;

      case "list":
        let { items } = section;
        return (
          <ul>
            {items.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        );

      case "heading1":
        if (section.children.length == 0) {
          return <Typography variant="h3">{section.textContent}</Typography>;
        }
        return section.children.map((child, childIndex) =>
          this.renderSection(child, index + "-" + childIndex)
        );

      case "heading2":
        if (section.children.length == 0) {
          return <Typography variant="h4">{section.textContent}</Typography>;
        }
        return section.children.map((child, childIndex) =>
          this.renderSection(child, index + "-" + childIndex)
        );

      case "heading3":
        if (section.children.length == 0) {
          return <Typography variant="h5">{section.textContent}</Typography>;
        }
        return section.children.map((child, childIndex) =>
          this.renderSection(child, index + "-" + childIndex)
        );

      case "special":
        return <div>{HtmlParser(section.htmlContent)}</div>;

      default:
        return "";
    }
  };

  if (Array.isArray(props.body)) {
    return props.body.map((section) => {
      return renderSection(section);
    });
  }

  return "";
}

function BreadCrumb(props) {
  return (
    <div>
      <div>
        <Button
          component={NavLink}
          to="/"
          startIcon={<Home />}
          endIcon={<KeyboardArrowRight />}
        >
          Home
        </Button>
        <Button
          component={NavLink}
          to="/updates"
          startIcon={<CardGiftcardOutlined />}
          endIcon={<KeyboardArrowRight />}
        >
          Blog
        </Button>
        <Button disabled>
          <RenderPostTitle />
        </Button>
      </div>
    </div>
  );
}

function NextPrevButtons(props) {
  const { post_id } = useParams();
  return (
    <ApiRequest
      autoload
      query={{
        blog_name: props.blog_name,
      }}
      params={{ post_id }}
      thread={BLG_API_SHOW_BLOG_POST}
      addOnThread={post_id}
    >
      {({ res, loading, error, ErrorView, RefreshButton }) => {
        let post = { prev_post: 0, next_post: 0 };
        if (typeof res === "object") {
          if (typeof res.data === "object") {
            if (typeof res.data.post == "object") {
              post = res.data.post;
            }
          }
        }

        return (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              {post.prev_post === 0 ? (
                ""
              ) : (
                <Button
                  component={NavLink}
                  to={`/blog/${post.prev_post}`}
                  startIcon={<ArrowBack />}
                >
                  PREVIOUS POST
                </Button>
              )}
            </Grid>
            <Grid item>
              {post.next_post === 0 ? (
                ""
              ) : (
                <Button
                  component={NavLink}
                  to={`/blog/${post.next_post}`}
                  endIcon={<ArrowForward />}
                >
                  NEXT POST
                </Button>
              )}
            </Grid>
          </Grid>
        );
      }}
    </ApiRequest>
  );
}

function RenderPostTitle(props) {
  const { post_id } = useParams();
  return (
    <ApiRequest
      autoload
      query={{
        blog_name: props.blog_name,
      }}
      params={{ post_id }}
      thread={BLG_API_SHOW_BLOG_POST}
      addOnThread={post_id}
    >
      {({ res, loading, error, ErrorView, RefreshButton }) => {
        let title = "";
        if (typeof res === "object") {
          if (typeof res.data === "object") {
            if (typeof res.data.post == "object") {
              title = res.data.post.title;
            }
          }
        }

        return title;
      }}
    </ApiRequest>
  );
}
