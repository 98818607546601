import { makeUrl as makeUrlOld } from "../hooks";
import Archives from "../pages/Archives";
import Blog from "../pages/Blog";
import HowToDonate from "../pages/HowToDonate";
import Landing from "../pages/Landing";
import Programs from "../pages/Programs/index";
import Projects from "../pages/Projects";
import CurrentProjects from "../pages/Projects/CurrentProjects";

const makeUrl = (title) => "/" + makeUrlOld(title);

const appLinks = {
  modules: [
    {
      main: "/",
      path: "/",
      name: "Home",
      icon: "",
      Component: () => "home",
    },
    {
      main: "/programs",
      path: "/programs",
      name: "Programs",
      icon: "",
      Component: Programs,
    },
    {
      name: "Current Projects",
      path: "/current-projects",
      Component: Projects,
    },
    // {
    //   main: "/about-us",
    //   path: "/about-us",
    //   name: "About Us",
    //   icon: "",
    //   Component: AboutUs,
    //   subLinks: [
    //     {
    //       name: "Our Team",
    //       Component: () => "",
    //       path: makeUrl("Our Team"),
    //     },
    //   ],
    // },
    // {
    //   path: "/our-history",
    //   name: "Our History",
    //   icon: "",
    //   Component: OurHistory,
    // },
    // {
    //   name: "Financial Clubs",
    //   path: makeUrl("Financial Clubs"),
    //   Component: FinancialClub,
    //   subLinks: [
    //     {
    //       name: "Weekly Lessons",
    //       path: makeUrl("Weekly Lessons"),
    //       Component: () => "",
    //     },
    //     {
    //       name: "Skills Training",
    //       Component: () => "",
    //       path: makeUrl("Skills Training"),
    //       subLinks: [
    //         {
    //           name: "Soap Making Project",
    //           Component: () => "",
    //           path: makeUrl("Soap Making"),
    //         },
    //         {
    //           name: "Candle Making Project",
    //           Component: () => "",
    //           path: makeUrl("Candle Making"),
    //         },
    //         {
    //           name: "Hair Braiding",
    //           Component: () => "",
    //           path: makeUrl("hair Braiding"),
    //         },
    //       ],
    //     },
    //   ],
    // },

    // {
    //   name: "Mini ministry",
    //   path: makeUrl("Mini ministry"),
    //   Component: MiniMinistry,
    // },
    {
      name: "Updates",
      path: makeUrl("Updates"),
      Component: Blog,
    },
    // {
    //   name: "History",
    //   path: makeUrl("History"),
    //   // Under history we the completed projects former projects
    //   // also in history its where we shall have the newsletters
    //   Component: Archives,
    //   // Component: Landing,
    // },
    // {
    //   main: "/staff",
    //   path: "/staff",
    //   name: "Staff",
    //   icon: "",
    //   // Component: () => "Staff",
    //   Component: Landing,
    // },

    // {
    //   name: "Other Projects",
    //   path: makeUrl("Other Projects"),
    //   Component: Projects,
    // },
    // {
    //   name: "Our Team",
    //   path: makeUrl("Our Team"),
    //   Component: () => (
    //     <div style={{ height: "50vh" }}>
    //       {" "}
    //       <h1 align="center" className="p-5 my-5">
    //         Page is under construction
    //       </h1>
    //     </div>
    //   ),
    // },
    {
      name: "Donate",
      path: makeUrl("Donate"),
      Component: HowToDonate,
    },
  ],
};

export default appLinks;
