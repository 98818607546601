import React from "react";
import { makeStyles, Grid, Container } from "@material-ui/core";
import { Route, Switch, useLocation } from "react-router-dom";
import { mappedLinks } from "../../maps";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    padding: "0px",
    margin: "0px",
    // backgroundColor: grey[200],
  },
  innerRoot: {
    width: "100%",
    padding: "0px",
    margin: "0px",
  },
  appbarWrapper: {
    width: "100%",
  },
}));

export default function MainWrapper(props) {
  const classes = useStyles();
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      {props.appbar}
      <div className="w-100 p-0 m-0">{props.children}</div>
    </React.Fragment>
  );
}
