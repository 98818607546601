import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PageNotFound from "../../../global/components/error_pages/PageNotFound";
import LandingMain from "./LandingMain";
import FinancialClubProgramme from "../Programs/FinancialClubProgramme";

export default function Landing(props) {
  const rootPath = useRouteMatch();

  return <LandingMain />;
}
