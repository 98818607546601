import {
  Container,
  Grid,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import { orange, teal } from "@material-ui/core/colors";
import { ArrowForward } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: teal[100],
    backgroundImage: "url('./images/intro-slider/donate.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff!important",
  },
}));

export default function HowToDonate(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} className={classes.header}>
        <Container>
          <Grid container spacing={3} alignItems="center" className="pt-5 pb-5">
            <Grid item xs={12} md={6}>
              <Typography color="inherit" variant="h2">
                Donate
              </Typography>
              <Typography className="pt-2 pb-2">
                To donate to Abundant Blessing Ministries, and receive a US Tax
                deductible receipt, contact Good Measure and note that your
                donation is for Abundant Blessing Ministries.
              </Typography>
              <Button
                size="large"
                variant="contained"
                color="primary"
                component="a"
                href="https://pushpay.com/g/goodmeasure"
                target="blank"
                endIcon={<ArrowForward />}
              >
                Click here to Donate Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
