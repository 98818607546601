import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowForward, YouTube } from "@material-ui/icons";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  slideImage: {
    width: "100%",
    backgroundSize: "cover",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
  },
  textWrapper: {
    background: "rgba(0,0,0,0.3)",
    width: "100%",
    color: "#fff",
  },
  sliderContentWrapper: {
    background:
      "linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.75) 70%)",
    ["& *"]: {
      color: "#fff",
    },
  },
  text: {
    fontSize: "40px",
    color: "#fff",
    lineHeight: "50px",
  },
  slideContent: {
    minHeight: "400px",
  },
  heading: {
    fontSize: "40px",
    color: "#fff",
    lineHeight: "50px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      lineHeight: "30px",
    },
  },
}));

export default function IntroSlider(props) {
  const classes = useStyles();
  const images = [
    // {
    //   url: "/images/intro-slider/fc.JPG",
    //   text: (
    //     <div class="d-flex align-items-center" style={{ height: "500px" }}>
    //       <div>
    //         <Typography
    //           variant="h4"
    //           style={{ color: "#fff" }}
    //           className={classes.text}
    //         >
    //           Every one deserves a chance to have hope and a better,
    //           sustainable, and healthy life
    //         </Typography>
    //         <Typography variant="h6" className="mt-2">
    //           Through our financial clubs people have been taught how to save
    //           money and have a healthy relationship with God, as well as
    //           biblical life principles.
    //         </Typography>
    //         <Button
    //           variant="contained"
    //           color="primary"
    //           className="mt-3"
    //           size="large"
    //           to="/financial-clubs"
    //           component={Link}
    //           endIcon={<ArrowForward />}
    //         >
    //           Learn more
    //         </Button>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      url: "/images/intro-slider/base-ministries.jpg",
      text: (
        <div class="d-flex align-items-center" style={{ height: "500px" }}>
          <div>
            <Typography
              variant="h1"
              style={{ color: "#fff" }}
              className={classes.heading}
            >
              Our programs emphasize empowering people spiritually,
              relationally, financially, and health-wise in our local
              communities.
            </Typography>
            <Typography variant="h6" className="mt-2">
              We strive to build stronger, more unified families through
              biblical teaching with practical applications. Our teachings help
              our members to learn self-respect and self-worth that is given to
              them by God, and can be passed on to their families.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className="mt-3 me-3"
              size="large"
              to="/programs"
              component={Link}
              endIcon={<ArrowForward />}
            >
              Learn more
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="mt-3 "
              size="large"
              href="https://www.youtube.com/@abundantblessingministries/videos"
              component={"a"}
              startIcon={<YouTube />}
            >
              Watch Our Ministries on YouTube
            </Button>
          </div>
        </div>
      ),
    },
    // {
    //   url: "/images/intro-slide-one.jpg",
    //   text: (
    //     <div class="d-flex align-items-center" style={{ height: "500px" }}>
    //       <div>
    //         <Typography
    //           variant="h1"
    //           style={{ color: "#fff" }}
    //           className={classes.text}
    //         >
    //           Financial Clubs
    //         </Typography>
    //         <Typography variant="h6" className="mt-2">
    //           We strive to build stronger, more unified families through
    //           biblical teaching with practical applications. Our teachings help
    //           our members to learn self-respect and self-worth that is given to
    //           them by God, and can be passed on to their families...
    //         </Typography>
    //         <Button
    //           variant="contained"
    //           color="primary"
    //           className="mt-3"
    //           size="large"
    //           to="/financial-clubs"
    //           component={Link}
    //           endIcon={<ArrowForward />}
    //         >
    //           Learn more
    //         </Button>
    //       </div>
    //     </div>
    //   ),
    // },
  ];
  return (
    <Grid container>
      <Grid item xs={12}>
        <Carousel
          infiniteLoop
          emulateTouch
          autoPlay
          showStatus={false}
          showThumbs={false}
          swipeable={true}
          useKeyboardArrows={true}
          showIndicators={false}
        >
          {images.map(({ url, text }, index) => (
            <div
              style={{
                backgroundImage: `url(${url})`,
              }}
              className={classes.slideImage}
              key={index}
            >
              <div className={classes.sliderContentWrapper}>
                <Container>
                  <Grid
                    container
                    alignItems="flex-end"
                    className={classes.slideContent}
                  >
                    <Grid item xs={12} md={8} align="left" className="py-5">
                      {text}
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
}
