import React from "react";
import Logo from "./logo.svg";
import LogoWhite from "./logo-white.svg";
import GoogleSignIcon from "./btn_google_light_normal_ios.svg";

const Images = {
  Logo: (props) => <img {...props} src={Logo} />,
  LogoWhite: (props) => <img {...props} src={LogoWhite} />,
  GoogleSignIcon: (props) => <img {...props} src={GoogleSignIcon} />,

  pageNotFound: "/images/page-not-found.png",

  landing: {
    card1: (props) => <img {...props} src="/images/landing/card1.svg" />,
    card2: (props) => (
      <img {...props} src="/images/patients-with-cognitive-deficit.png" />
    ),
    p1: "/images/landing/p1.jpg",
    p2: "/images/landing/p2.jpg",
    p3: "/images/landing/p3.jpg",
    p4: "/images/landing/p4.jpg",
    p5: "/images/landing/p5.jpg",
    vision: "/images/landing/vision.png",
    mission: "/images/landing/mission.png",
  },

  programs: {
    placeholder: "/images/programs/prog.jpg",
    foodOutreach: {
      staving: "/images/programs/food-outreach/staving.jpg",
      director: "/images/programs/food-outreach/director.jpg",
      s1: "/images/programs/food-outreach/s1.jpg",
      s2: "/images/programs/food-outreach/s2.jpg",
      s3: "/images/programs/food-outreach/s3.jpg",
      s4: "/images/programs/food-outreach/s4.jpg",
    },
  },
};

export default Images;
