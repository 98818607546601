import {
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import { blueGrey, orange } from "@material-ui/core/colors";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React, { useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import ApiRequest from "../../../api/ApiRequest";
import { BLG_API_GET_BLOG_POSTS } from "../../../api/apiThreads";
import { makeUrl } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    backgroundColor: blueGrey[200],
    backgroundImage: "url('/images/backgrounds/blog-bg.jpg')",
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
  },
  header: {
    height: "200px",
    display: "flex",
    alignItems: "center",
  },
  categoriesTab: {
    height: "100px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f1f3f6",
  },
  postCard: {
    boxShadow: "0 4px 8px 0 rgba(37,54,71,.16)",
    "-webkit-transition": "box-shadow .3s ease,-webkit-transform .3s ease",
    transition:
      "box-shadow .3s ease,transform .3s ease,-webkit-transform .3s ease",
    "&:hover": {
      boxShadow: "0 4px 15px -4px rgba(37,54,71,.48)",
      "-webkit-transform": "translate(0,-5px)",
      "-ms-transform": "translate(0,-5px)",
      transform: "translate(0,-5px)",
    },
    height: "100%",
  },
  postHeaderImage: {
    width: "100%",
    height: "100px",
    objectFit: "cover",
  },

  header: {
    backgroundColor: orange[200],
    backgroundImage: "url('./images/intro-slider/updates.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff!important",
  },

  body: {
    backgroundColor: "#f1f3f6",
  },
  postCard: {
    height: "470px",
    boxShadow: "0 4px 8px 0 rgba(37,54,71,.16)",
    "-webkit-transition": "box-shadow .3s ease,-webkit-transform .3s ease",
    transition: "box-shadow .3s ease,-webkit-transform .3s ease",
    transition: "box-shadow .3s ease,transform .3s ease",
    transition:
      "box-shadow .3s ease,transform .3s ease,-webkit-transform .3s ease",
    "&:hover": {
      boxShadow: "0 4px 15px -4px rgba(37,54,71,.48)",
      ["-webkit-transform"]: "translate(0,-5px)",
      ["-ms-transform"]: "translate(0,-5px)",
      transform: "translate(0,-5px)",
    },
  },
  postHeaderImage: {
    width: "100%",
    height: "100px",
    objectFit: "cover",
    transition: "0.3s ease",
    "&:hover": {
      opacity: "0.7",
      transition: "0.3s ease",
    },
  },
  postTitle: {
    fontFamily: "Poppins,sans-serif",
    color: "#253647",
    fontSize: "18px",
    fontWeight: 700,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  cardFooterLink: {
    marginTop: "0px",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700,
    textDecoration: "none",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    marginTop: "20px",
    color: theme.palette.secondary.main,
    "&:hover": {
      color: "rgba(37,54,71,.8)",
    },
  },
}));

export default function BlogMain(props) {
  const classes = useStyles();
  const [apiQueryState, setApiQueryState] = useState({
    blog_name: "UPDATES",
  });

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <Container>
            <Grid
              container
              spacing={3}
              alignItems="center"
              className="pt-5 pb-3"
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h2" color="inherit">
                  Updates
                </Typography>
                <Typography className="pt-2 pb-5">
                  Read about our recent activities and publications from our
                  ministry. You can also subscribe to our newsletter to get the
                  most recent updates.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12} className={clsx(classes.body, "pt-5 pb-5")}>
          <Container className="my-5">
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <ApiRequest
                  query={apiQueryState}
                  addOnThread={JSON.stringify(apiQueryState)}
                  autoload
                  thread={BLG_API_GET_BLOG_POSTS}
                  dataKey="posts"
                  limit={8}
                  page={1}
                  hasPagination={true}
                >
                  {({
                    res,
                    error,
                    loading,
                    ErrorView,
                    RefreshButton,
                    loadingPage,
                    nextPage,
                  }) => {
                    if (loading === true) {
                      return <CircularProgress />;
                    }

                    if (error) {
                      return (
                        <div>
                          <ErrorView />
                        </div>
                      );
                    }

                    let posts = null;
                    let total = 0;
                    if (typeof res == "object") {
                      if (Array.isArray(res.posts)) {
                        posts = res.posts;
                      }

                      if (typeof res.data == "object") {
                        total = res.data.total;
                      }
                    }

                    if (posts === null) {
                      return <div>Failed to load blog posts</div>;
                    }

                    return (
                      <React.Fragment>
                        {posts.map((post, index) => {
                          return (
                            <LazyLoadComponent
                              afterLoad={() => {
                                if (
                                  index === posts.length - 1 &&
                                  loadingPage === false
                                ) {
                                  nextPage();
                                }
                              }}
                              key={index}
                            >
                              <BlogPostPreviewCard index={index} {...post} />
                            </LazyLoadComponent>
                          );
                        })}
                        {loadingPage && <LoadingMorePosts />}
                      </React.Fragment>
                    );
                  }}
                </ApiRequest>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export const BlogPostPreviewCard = (props) => {
  const { summary, title, image, post } = props;
  const classes = useStyles();
  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.postCard}>
        <Link
          to={`/updates/${post}-${title.replaceAll(" ", "_").toLowerCase()}`}
        >
          <div className={classes.postHeader}>
            <img alt={title} className={classes.postHeaderImage} src={image} />
          </div>
        </Link>
        <div className={clsx("p-4")}>
          <Typography
            component={Link}
            to={`/updates/${post}-${title.replaceAll(" ", "_").toLowerCase()}`}
            color="secondary"
            className={classes.postTitle}
          >
            {title}
          </Typography>
          <Typography className="pt-3 pb-5">
            {summary.slice(0, 257)}...
          </Typography>
          <Typography
            className={classes.cardFooterLink}
            component={Link}
            to={`/updates/${post}-${title.replaceAll(" ", "_").toLowerCase()}`}
            color="secondary"
          >
            READ THE FULL POST
          </Typography>
        </div>
      </Paper>
    </Grid>
  );
};

export function LoadingMorePosts(props) {
  return [1, 2, 3, 4].map((item, index) => {
    return (
      <Grid item xs={6} sm={3} md={4} lg={3} key={index}>
        <Skeleton
          variant="rect"
          width="100%"
          height="200px"
          style={{ width: "100%" }}
        />
      </Grid>
    );
  });
}
