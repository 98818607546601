import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#FFAD42",
      dark: "#BB4D00",
      main: "#009348",
      contrastText: "#fff",
    },
    secondary: {
      main: "#F7941D",
      light: "#5F707B",
      dark: "#0E1E27",
      text: "#fff",
    },
    colored: {
      green: "#00FF00",
      orange: "#F18D1E",
    },
    brand: {
      yellow: "#FFF200",
      green: "#009348",
      red: "#FF0202", // main
      brown: "#683F15",
      orange: "#F7941D",
      blue: "#1B75BC", // ,main
    },
    type: "light",
    mainWrapper: {
      grads: {
        innerRoot: {
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        },
        appbarWrapper: {
          // backgroundColor: "rgba(245, 124, 0, 0.8)", // orange
          backgroundColor: "rgba(53, 69, 79, 0.9)", // blueGrey
        },
      },
    },
    grads: {
      transWhite: {
        backgroundColor: "rgba(100%, 100%, 100%, 0.5)", // white background
      },
    },
  },
  boxShadows: [
    `0 1px 2px 0 rgba(0,0,0,0.05)`,
    `0 2px 5px 0 rgba(0,0,0,0.05)`,
    `0 5px 10px 0 rgba(0,0,0,0.05)`,
    `0 10px 15px 0 rgba(0,0,0,0.05)`,
  ],
  bgColor: "#F2F3F5",
  bgColor: "#2680EB",
  typography: {
    color: "#253647",
    fontWeightRegular: "300",
    // fontFamily: "Poppins,sans-serif",
    fontFamily: "Jost, sans-serif",
    color: "#253647",
    // fontSize: "16px",
    lineHeight: "26px",
    fontWeight: "400",
    body1: {
      fontFamily: "Poppins,sans-serif",
      fontFamily: "Jost, sans-serif",

      fontSize: "16px",
      lineHeight: "26px",
    },
    h1: {
      fontFamily: "Poppins,sans-serif",
      fontFamily: "Jost, sans-serif",
      // fontFamily: "Kaushan Script, cursive",
      color: "#253647",
      fontWeight: 700,
      fontSize: "40px",
    },
    h2: {
      fontFamily: "Poppins,sans-serif",
      fontFamily: "Jost, sans-serif",
      // fontFamily: "Kaushan Script, cursive",

      color: "#253647",
      fontSize: "30px",
      lineHeight: "42px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "24px",
      fontFamily: "Poppins,sans-serif",
      fontFamily: "Jost, sans-serif",
      // fontFamily: "Kaushan Script, cursive",
      color: "#253647",
      lineHeight: "34px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "18px",
      color: "#253647",
      fontWeight: 600,
      lineHeight: "28px",
      fontFamily: "Poppins, sans-serif",
      fontFamily: "Jost, sans-serif",
      // fontFamily: "Kaushan Script, cursive",
    },
    h5: {
      fontSize: "1rem",
      color: "#253647",
      fontWeight: 600,
      lineHeight: "1.2rem",
      fontFamily: "Poppins, sans-serif",
      fontFamily: "Jost, sans-serif",
    },
    h6: {
      fontSize: "1.05rem",
      color: "#253647",
      fontWeight: 600,
      lineHeight: "1.4rem",
      fontFamily: "Poppins, sans-serif",
      fontFamily: "Jost, sans-serif",
    },
  },
});

export default theme;
