export const HOST = "https://apis.hapipie.com";
export const APP_PATH = window.location.protocol + "//" + window.location.host;
export const headers = {
  json: {
  headers: {
      "Content-Type": "application/json",
    },
  },
};

export const CONFIG = {
  IMG_DIR: HOST + "/images-container",
};

export const axiosConfig = {
  
}
