import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  makeStyles,
  Snackbar,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import { ArrowBack, ArrowForward, Close, Refresh } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import Axios from "axios";
import clsx from "clsx";
import propTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import theme from "../site/theme/app-theme";
import { apiEndPoints } from "./apiEndPoints";
import { readUserDataOnDevice } from "./cacheUserOnDevice";
import store, { cacheRequest, updateStore } from "./store";

const useStyles = makeStyles((theme) => ({
  refreshButton: {
    width: "30px",
    height: "30px",
    borderRadius: "5px",
    backgroundColor: teal[500],
    color: "#fff",
    ["&:hover"]: {
      color: teal[500],
      border: "solid 1px " + teal[500],
    },
  },
  paginationBtn: {
    width: "30px",
    height: "30px",
    borderRadius: "0px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ["& *"]: {
      fontSize: "1rem",
    },
    ["&:hover"]: {
      color: theme.palette.primary.main,
    },
  },
  paginationText: {},
  paginationBtnRoot: {
    // border: "solid 1px " + theme.palette.primary.main,
    padding: "0px",
  },
  divider: {
    width: "1px",
    height: "25px",
    background: "#000",
  },
  pageIndex: {
    width: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  refreshButtonReloading: {
    ["& *"]: {
      fontSize: "2rem",
    },
  },
}));

let loadingRequests = {};
let nextPageCalls = 0;
export default class ApiRequest extends React.PureComponent {
  constructor(props) {
    super(props);

    /**
     * Iniialize state state will only have the dynamic data of the api request
     */
    this.state = {
      validationErrorMessage: {},
      validationErrors: {},
      errorMessage: "",
      message: "",
      payload: this.props.initialPayload || {},
      data: this.props.initialData,
      loading: false,
      loadingState: 0,
      refreshTime: 500,
      error: false,
      refreshing: false,
      formData: new FormData(),
      fileInputs: {},
      showMessageSnackBar: false,
      page: 1,
      sorting: false,
      pagesLoaded: [],
      showDialog: false,
      dialogTitle: "",
      dialogMessage: "",
      errorType: "",
      currentPage: 1,
      pages: 1,
      loadingPage: false,
      page: 1,
      total: null,
      searching: false,
      completed: false, // when the form has finished loading i will be in completed state
    };

    this.headers = props.headers || {};
    this.button = props.button || false;
    this.callbacks = {
      success: () => {},
      error: () => {},
      validationError: () => {},
      resetFormCallback: (data) => {},
      ...this.props.callbacks,
    };

    /**
     * When  ever we make a request, we have response object. This response object 
       {
          data: {
            ['key-tha-holds-the-new-data']: {} || []
          }
       }
     */
    this.uiUpdateKey = props.uiUpdateKey || false;

    // thread of the existing fetched data in the ui if that data is not yet there, it will not be added
    this.uiUpdateThread = props.uiUpdateThread || false;

    // they of the data we are going to update
    this.uiUpdateType = props.uiUpdateType || false;

    // the response key which comes inside the date respose
    this.responseKey = props.responseKey || false;

    // method
    this.method = props.method || "GET";

    // check if the api request is goig to be used in a hook
    this.isHook = props.isHook || false;

    /**
     * [this.props.addOnThread]
     * This is used to enable multiple requests
     * Example we might be fetching data using the same thread but different ids.
     * An addon thread can be that id
     */
    if (typeof this.props.addOnThread === "undefined") {
      this.addOnThread = "";
    } else {
      this.addOnThread = this.props.addOnThread;
    }
    this.thread = this.props.thread + this.addOnThread;

    if (this.props.hasPagination === true) {
      this.thread =
        this.props.thread +
        this.addOnThread +
        this.props.page +
        this.props.limit;
    }

    // ref for the snack bar
    this.ref = React.createRef();
  }

  /**
   * Reset the state to the initial state
   */
  resetState = () => {};

  /**
   * Load Next page if the request has pagination
   */
  loadNextPage = (options = {}) => {};

  /**
   * Input for input fields
   */
  input = (options) => {
    const { name, defaultValue, type } = options;
    // setPayload({
    //   ...payload,
    //   [name]: options.value || ""
    // })
    const showHelperText = (name) => {
      if (typeof this.state.validationErrors !== "undefined") {
        if (name in this.state.validationErrors) {
          return {
            helperText: this.state.validationErrors[name],
            error: true,
          };
        }
      }
      return {
        helperText: options.helperText || "", //JSON.stringify(validationErrors);
      };
    };
    return {
      ...options,
      onChange: (e) => {
        const { name, value, type, checked } = e.target;

        this.setState({
          ...this.state,
          payload: {
            ...this.state.payload,
            [name]: type == "checkbox" || type === "radio" ? checked : value,
          },
        });
      },
      value:
        typeof this.state.payload[name] === "string"
          ? this.state.payload[name].trimStart()
          : this.state.payload[name] || defaultValue,
      ...showHelperText(name),
    };
  };

  /**
   * Set Payload
   */
  setPayload = (payload) => {
    this.setState({
      ...this.state,
      payload: {
        ...this.props.initialPayload,
        ...this.state.payload,
        ...payload,
      },
    });
  };

  apiConfig = (options) => {
    var method = this.props.method;
    var url = this.props.endPoint;

    if (typeof this.props.thread !== "undefined") {
      if (this.props.thread in apiEndPoints) {
        var {
          endPoint,
          method,
          uiUpdateKey,
          uiUpdateThread,
          uiUpdateType,
          responseKey,
          headers,
        } = apiEndPoints[this.props.thread];
        if (typeof endPoint === "function") {
          var url = endPoint(this.props.params);
        } else {
          var url = endPoint;
        }

        this.headers = { ...headers, ...this.headers };

        /**
         * Work  on the ui updates
         */
        if (this.uiUpdateKey == false) {
          this.uiUpdateKey = uiUpdateKey;
        }

        if (this.uiUpdateThread === false) {
          this.uiUpdateThread = uiUpdateThread;
        }

        if (this.uiUpdateType == false) {
          this.uiUpdateType = uiUpdateType;
        }

        if (this.responseKey == false) {
          this.responseKey = responseKey;
        }
      }
    } else {
      // alert("apiEndPoint: is undeined");
    }

    // payload passed as a prod to api Request. this is so since the initial payload can be overided at any time.
    let propPayload = {};
    if (typeof this.props.payload == "object") {
      propPayload = this.props.payload;
    }

    // check for query params
    var queryString = "";
    if (typeof this.props.query === "object") {
      queryString = "?";
      for (var key in this.props.query) {
        queryString += `${key}=${this.props.query[key]}&`;
      }

      for (var key in this.state.payload) {
        queryString += `${key}=${this.state.payload[key]}&`;
      }
      queryString = encodeURI(queryString);
      // add the payload to the query string,
      // if (this.isQueryInput === true) {
      //   for (var key in this.state.payload) {
      //     queryString += `&${key}=${this.state.payload[key]}`;
      //   }
      // }
    } else {
      if (typeof method == "string") {
        if (method.toUpperCase() == "get".toUpperCase()) {
          queryString = "?";

          let _qdata = {
            ...this.props.initialPayload,
            ...this.state.payload,
            ...propPayload,
          };
          for (var key in _qdata) {
            let value = _qdata[key];
            if (typeof value == "object") {
              value = JSON.stringify(value);
            }
            queryString += `${key}=${value}&`;
          }
          // remove the & the end
          queryString = queryString.slice(0, queryString.length - 1);
          queryString = encodeURI(queryString);
        }
      }
    }

    var data = {
      ...this.props.initialPayload,
      ...this.state.payload,
      ...propPayload,
    };

    var contentType = "application/json";

    // handle file upload if the form has file inputs
    if (
      this.props.withFileUpload === true ||
      this.props.withFileUpload === "true"
    ) {
      contentType = `multipart/form-data; boundary=${Math.random()
        .toString()
        .substr(2)}`;
      var formData = new FormData();
      for (var key in this.state.payload) {
        formData.append(key, this.state.payload[key]);
      }
      data = formData;
    }

    let bearerToken = readUserDataOnDevice().token;

    // if no query string provided url will be fine.
    url = url + queryString;
    this.method = method;
    this.url = url;
    // console.clear();
    if (this.props.hasPagination == true) {
      if (this.thread in store.getState().dataStore) {
        let _page = store.getState().dataStore[this.thread];
        if (options.loadNextPage == true) {
          let nextPage = _page.current_page + 1;
          console.log("Pages loaded: ", _page.pagesLoaded);
          if (_page.pagesLoaded.indexOf(nextPage) == -1) {
            this.url =
              url + `page=${options.nextPage}&limit=${this.props.limit || 20}`;
          }
        }
      } else {
        this.url =
          url + `page=${this.props.page || 1}&limit=${this.props.limit || 20}`;
      }
    }

    return {
      url: this.url,
      method,
      contentType: contentType,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "*",
        Authorization: "Bearer " + bearerToken,
        ...this.headers,
      },
      widthCredentials: true,
    };
  };

  componentDidUpdate() {
    this.callbacks = {
      success: () => {},
      error: () => {},
      validationError: () => {},
      resetFormCallback: (data) => {},
      ...this.props.callbacks,
    };

    if (typeof this.props.addOnThread === "undefined") {
      this.addOnThread = "";
    } else {
      this.addOnThread = this.props.addOnThread;
    }

    const oldThread = this.thread;
    this.thread = this.props.thread + this.addOnThread;
    const dataStore = store.getState().dataStore;

    /**
     * If there was un update on the thread and its not the same as the old one
     * WE shall need to request that data from the server for
     */
    if (oldThread !== this.thread) {
      if (typeof this.thread !== undefined) {
        if (this.thread in dataStore) {
          this.setState({
            ...this.state,
            data: dataStore[this.thread],
          });
        } else {
          if (this.props.autoload === true || this.props.autoload === "true") {
            if (this.state.loading == false) {
              this.request();
            }
          }
        }
      }
    }

    // check and see if the snack bar mounted and change the position
    if (typeof this.ref.current !== "undefined") {
    }

    const unsubscribe = store.subscribe(() => {
      try {
        const dataStore = store.getState().dataStore;
        const cacheRequestStore = store.getState().cacheRequestStore;

        if (this.thread in dataStore) {
          if (
            JSON.stringify(dataStore[this.thread]) !==
            JSON.stringify(this.state.data)
          ) {
            this.setState({
              ...this.state,
              ...dataStore[this.thread],
            });
          }
          // unsubscribe();
        }

        if (this.thread in cacheRequestStore) {
          if (cacheRequestStore[this.thread] === true) {
            store.dispatch(
              cacheRequest({
                thread: this.thread,
                refresh: false,
              })
            );
            this.refreshRequest();
          }
        }
        return;
      } catch (error) {
        //console.log("Error encountred:: ", error);
      }
    });
  }

  componentDidMount() {
    const dataStore = store.getState().dataStore;
    let thread = this.thread;
    if (this.props.hasPagination === true) {
      thread = this.props.thread + this.addOnThread;
    }

    if (typeof this.thread !== undefined) {
      if (thread in dataStore) {
        this.setState({
          ...this.state,
          [this.props.dataKey]: dataStore[thread][this.props.dataKey],
          data: dataStore[thread],
        });
      } else {
        if (this.props.autoload === true || this.props.autoload === "true") {
          if (
            this.state.loading === false &&
            this.state.loadingPage === false
          ) {
            this.request();
          }
        }
      }
    }

    //Subscribe for future changes.
    const unsubscribe = store.subscribe(() => {
      const dataStore = store.getState().dataStore;
      const cacheRequestStore = store.getState().cacheRequestStore;

      if (this.thread in dataStore) {
        if (
          JSON.stringify(dataStore[this.thread]) !==
          JSON.stringify(this.state.data)
        ) {
          this.setState({
            ...this.state,
            ...dataStore[this.thread],
          });
        }
        // unsubscribe();
        return;
      }

      if (this.thread in cacheRequestStore) {
        if (cacheRequestStore[this.thread] === true) {
          store.dispatch(
            cacheRequest({
              thread: this.thread,
              refresh: false,
            })
          );
          this.refreshRequest();
        }
      }
    });
  }

  /**
   * @name errorView
   * @type ReactComponent
   * @description Views the error to the user
   */
  errorView = (props) => {
    if (this.state.error === true) {
      return (
        <div align="center">
          <LazyLoadImage
            src="/images/error-encountred.png"
            style={{
              width: "auto",
              maxWidth: "200px",
              height: "auto",
              maxHeight: "200px",
            }}
          />
          <Typography className="mt-3">{this.state.errorMessage}</Typography>
          <Button
            onClick={() => this.refreshRequest()}
            endIcon={
              this.state.refreshing ? (
                <CircularProgress color="secondary" size="20px" />
              ) : (
                <Refresh />
              )
            }
          >
            Try again
          </Button>
        </div>
      );
    }

    return "";
  };

  /**
   * Handle the file input change
   */
  onFileInputChange = (options) => (e) => {
    if (typeof options !== "object") {
      options = {};
    }
    var file = e.target.files[0];
    const { name } = e.target;
    let maxFileSize = e.target.getAttribute("maxFileSize") || 15;
    if (typeof file === "undefined") {
      return false;
    }

    if (typeof file.type === "undefined") {
      return false;
    }
    var size = (file.size * 10 ** -6).toFixed(0);

    if (Number(size) > Number(maxFileSize)) {
      alert(
        `File Size is greater then ${maxFileSize}mb \n Your file is: ${size}`
      );
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    this.setState({
      ...this.state,
      fileInputs: {
        ...this.state.fileInputs,
        [name]: {
          rendering: true,
          rendered: false,
        },
      },
    });
    reader.onloadend = () => {
      /**
       * Compression has  a bug with the image
       * If the type of file is not image it will not work well
       */
      this.setState({
        ...this.state,
        payload: {
          ...this.state.payload,
          [name]: file,
        },
        fileInputs: {
          ...this.state.fileInputs,
          [name]: {
            rendering: false,
            rendered: true,
            // changed it from url to originalUrl because we have compressed version of the file
            originalUrl: reader.result,
            fileName: file.name,
          },
        },
      });
      if (typeof options.onContentLoaded == "function") {
        options.onContentLoaded({ url: reader.result });
      }
    };

    // functionality for Small Size file compression to base64
    var img = new Image();
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var cw = canvas.width;
    var ch = canvas.height;
    var maxW = options.maxWidth || 600;
    var maxH = options.maxHeight || 600;

    img.onload = () => {
      var iw = img.width;
      var ih = img.height;
      var scale = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      // the small scaled image we could be able to pass in options
      // for compression ratio
      let imageQuality = options.imageQuality || 0.5;
      let smallImageUrl = canvas.toDataURL("image/jpeg", imageQuality);
      //console.log("Small image quality:: ", smallImageUrl);
      this.setState({
        ...this.state,
        payload: {
          ...this.state.payload,
          [name]: file,
        },
        fileInputs: {
          ...this.state.fileInputs,
          [name]: {
            ...this.state.fileInputs[name],
            rendering: false,
            rendered: true,
            // changed it from url to originalUrl because we have compressed version of the file
            url: smallImageUrl,
            fileName: file.name,
          },
        },
      });
      if (typeof options.onContentLoaded == "function") {
        options.onContentLoaded({
          url: smallImageUrl,
          originalUrl: this.state.fileInputs[name].originalUrl,
        });
      }
    };
    img.src = URL.createObjectURL(file); // this line is needed
  };

  /**
   * @name resetForm
   * @param
   * @description Reset the form and make it ready to be reused in the future
   */
  resetForm = () => {
    // the business logic has not been implemented yet
    this.setState({
      ...this.state,
      payload: this.props.initialPayload || {},
      data: this.props.initialData || {},
    });
  };

  /**
   * Reload request threads
   */
  reloadRequestThreads = () => {
    if (Array.isArray(this.props.reloadOnSuccess) == true) {
      if (typeof this.props.reloadOnSuccess.map == "function") {
        try {
          this.props.reloadOnSuccess.map((thread) => {
            store.dispatch(
              cacheRequest({
                thread: thread,
                refresh: true,
              })
            );
          });
        } catch (error) {
          //console.log(error);
        }
      }
    }
  };

  /**
   * File input
   * This is used when there is need to upload files to the server using the same form
   */
  fileInput = (props) => {
    if (typeof props.children === "function") {
    }
    const inputId = uuid();

    const Label = (props) => (
      <label {...props} htmlFor={inputId} className={props.className}>
        {props.children}
      </label>
    );

    // when file render has rendered the file
    let onContentLoaded = props.onContentLoaded;
    if (
      typeof onContentLoaded === "undefined" ||
      typeof onContentLoaded == "null"
    ) {
      onContentLoaded = (options = {}) => {};
    }

    return (
      <React.Fragment>
        <input
          type="file"
          id={inputId}
          accept={props.accept}
          onChange={this.onFileInputChange({ ...props, onContentLoaded })}
          name={props.name}
          maxFileSize={props.maxFileSize}
          className={clsx(props.inputClassName)}
        />
        {typeof props.children === "function"
          ? props.children({
              ...this.state.fileInputs[props.name],
              ...{ Label: Label },
            })
          : props.children}
      </React.Fragment>
    );
  };

  /**
   * Response handler for all requests
   */
  responseHandler = (res) => {
    var state = {
      message: "",
      loading: false,
      data: {},
      errorMessage: "",
      error: false,
      refreshing: false,
      validationErrors: {},
    };

    if (typeof res.data.errorCode !== "undefined") {
      this.callbacks.error(res.data);
      state = {
        ...state,
        errorMessage: "Error",
        data: this.props.initialData,
      };
    }

    if ("success" in res.data) {
      // Request was not successful
      if (res.data.success === false) {
        // Check if error exists in the payload
        if ("error" in res.data) {
          // There in an error with the request. this error is known at the server
          if (res.data.error === true) {
            this.callbacks.error(res.data);
            state = {
              ...state,
              errorMessage: res.data.data.message,
              data: res.data,
            };
          }
          // Check if there is a validation error
          if (res.data.validationError == true) {
            this.callbacks.validationError(res.data.data);
            state = {
              ...state,
              // errorMessage: res.data.message,
              validationErrors: res.data.data.errors,
              showMessageSnackBar: true,
              errorType: "VALIDATION_ERROR",
              errorTitle: "Filed Validation Error",
              errorMessage:
                res.data.data.errors[Object.keys(res.data.data.errors)[0]],
              errorSeverity: "warning",
            };
          } else {
            state = {
              ...state,
              error: true,
              showMessageSnackBar: true,
            };
          }
        }
      } else {
        /**
         * Let us handle the update on the data that has to be updated
         * When a request has finished loading, it either deletes, adds or gets new data from the server,
         * we would like to check and see if their is some update which needs to be handled.
         */
        let dataStore = JSON.parse(JSON.stringify(store.getState().dataStore));
        if (this.uiUpdateThread in dataStore) {
          let oldData = dataStore[this.uiUpdateThread].data;
          let list, newList, newItem, newData;
          if (typeof this.method == "string") {
            this.method = this.method.toUpperCase();
          }
          switch (this.method) {
            case "POST":
              // Lets know hoe to update the existing ui. Should we update, push , delete, or do something else
              switch (this.uiUpdateType) {
                case "push":
                  list = [];
                  if (Array.isArray(oldData[this.uiUpdateKey])) {
                    list = [...oldData[this.uiUpdateKey]];
                  }
                  newItem = res.data.data[this.responseKey];
                  newList = [];
                  newList.push(newItem, ...list);

                  newData = { ...oldData };
                  newData[this.uiUpdateKey] = newList;

                  store.dispatch(
                    updateStore({
                      thread: this.uiUpdateThread,
                      data: {
                        data: {
                          data: newData,
                        },
                      },
                    })
                  );
                  break;

                case "replace":
                  if (typeof oldData[this.uiUpdateKey] !== "object") {
                    newData = {
                      [this.uiUpdateKey]: {},
                    };
                  } else {
                    newData = { ...oldData };
                  }
                  newItem = res.data.data[this.responseKey];
                  newData[this.uiUpdateKey] = newItem;

                  store.dispatch(
                    updateStore({
                      thread: this.uiUpdateThread,
                      data: {
                        data: {
                          data: newData,
                        },
                      },
                    })
                  );
                  break;
                default:
                  break;
              }
              break;

            case "PATCH":
              break;

            case "DELETE":
              // Lets know hoe to update the existing ui. Should we update, push , delete, or do something else

              break;

            default:
              break;
          }
        }

        /**
         * Reload some requests if the request has been successfull
         */
        if (this.props.reloadOnSuccess != undefined) {
          this.reloadRequestThreads();
        }

        // call the success callback which can be used by the user incase they need to add some data
        this.callbacks.success(res.data, this);
        if (typeof this.props.successDialog == "object") {
          // check the structure
        }

        /**
         * Reset a form when the result are successfull and have some thing new
         */
        this.callbacks.resetFormCallback(res.data, this.resetForm);
        state = {
          ...state,
          ["completed"]: true,
          data: res.data,
        };
        // const
      }
    } else if (this.props.customResponse) {
      state = {
        ...state,
        error: false,
        showMessageSnackBar: false,
        data: res.data,
      };
    } else {
      state = {
        ...state,
        error: true,
        showMessageSnackBar: true,
      };

      // callbacks(res.data);
    }

    /**
     * check if pagination is enabled
     */

    if (typeof this.thread !== "undefined") {
      if (typeof res.data == "object") {
        if (res.data.success == true) {
          // if (store.getState().dataStore[res.config.url] == true) {
          //   this.setState({
          //     ...this.state,
          //     ...state,
          //     loadingPage: false,
          //   });
          //   return false;
          // } else {
          //   store.dispatch(
          //     updateStore({
          //       thread: res.config.url,
          //       data: true,
          //     })
          //   );
          // }
          if (this.props.hasPagination == true) {
            let currentPage = 1;
            let pagesLoaded = {};
            let pageData;
            let _page = {};
            if (this.thread in store.getState().dataStore) {
              _page = JSON.parse(
                JSON.stringify(store.getState().dataStore[this.thread])
              );
              currentPage = _page.current_page + 1;

              /**
               * Page already exists
               * This can be so if the page has refreshed or completed searching
               */
              if (_page.pagesLoaded.indexOf(currentPage) > -1) {
                store.dispatch(
                  updateStore({
                    hasPagination: true,
                    thread: this.thread,
                    ..._page,
                    data: res.data.data,
                  })
                );
                this.setState({
                  ...this.state,
                  ...state,
                  loadingPage: false,
                });

                return true; // ignore updating the data store...
              }
              if (
                JSON.stringify(_page.data[this.props.dataKey]) ==
                JSON.stringify(res.data.data[this.dataKey])
              ) {
                this.setState({
                  ...this.state,
                  ...state,
                  loadingPage: false,
                });
                return true;
              }
              pagesLoaded = [..._page.pagesLoaded, res.data.data.current_page];
              pageData = [
                ..._page[this.props.dataKey],
                ...res.data.data[this.props.dataKey],
              ];
            } else {
              pagesLoaded = [res.data.data.current_page];
              pageData = [...res.data.data[this.props.dataKey]];
            }

            if (res.data.data.current_page >= 1) {
              store.dispatch(
                updateStore({
                  thread: this.thread,
                  hasPagination: true,
                  currentPage,
                  pagesLoaded,
                  limit:
                    res.data.data.limit ||
                    res.data.data.per_page ||
                    this.state.limit ||
                    this.props.limit,
                  ...res.data.data,
                  data: res.data.data,
                  [this.props.dataKey]: pageData,
                  page: res.data.data.current_page,
                  total: res.data.data.total,
                })
              );
            } else {
              if (this.state.searching == true) {
                alert("Searching... ");
                store.dispatch(
                  updateStore({
                    hasPagination: true,
                    thread: this.thread,
                    ..._page,
                    // data: res.data.data,
                    searchResults: res.data.data,
                  })
                );
              } else {
                store.dispatch(
                  updateStore({
                    hasPagination: true,
                    thread: this.thread,
                    ..._page,
                    data: res.data.data,
                  })
                );
              }
            }
          } else {
            store.dispatch(
              updateStore({
                thread: this.thread,
                data: res.data,
              })
            );
          }
        }
      }
    }

    this.setState({
      ...this.state,
      ...state,
      loadingPage: false,
    });
  };

  /**
   * Handle errors that happen when we are making requests
   */
  errorHandler = (error) => {
    this.setState({
      ...this.state,
      ["error"]: true,
      loading: false,
      completed: false,
      refreshing: false,
    });
    this.callbacks.error(error.data);
  };

  /**
   * Make a request to the server
   */
  request = (options = {}) => {
    if ("storedRequests" in window) {
    } else {
      window["storedRequests"] = {};
    }

    const config = this.apiConfig({ ...options });
    if (this.props.hasPagination == true) {
      if (options.refresh == false) {
        let urlExists = store.getState().dataStore[config.url];
        if (
          urlExists === true ||
          urlExists == "LOADING" ||
          urlExists == "FAILED_TO_LOAD"
        ) {
          return false;
        } else {
          store.dispatch(
            updateStore({
              thread: config.url,
              data: "LOADING",
            })
          );
        }
      } else {
      }

      if (options.loadNextPage == true) {
        this.setState({ ...this.state, loadingPage: true });
        Axios(config).then(this.responseHandler).catch(this.errorHandler);
      } else {
        if (Object.keys(store.getState().dataStore).indexOf(this.thread) > -1) {
          return false;
        } else {
        }
        this.setState({ ...this.state, loading: true, completed: false });
        Axios(config).then(this.responseHandler).catch(this.errorHandler);
      }
    } else {
      if (this.state.loading == false) {
        this.setState({ ...this.state, loading: true, completed: false });
        Axios(config).then(this.responseHandler).catch(this.errorHandler);
      }
    }
  };

  /**
   * Refresh the request
   */
  refreshRequest = () => {
    try {
      this.setState({ ...this.state, refreshing: true });
      Axios(
        this.apiConfig({
          loadingNextPage: false,
        })
      )
        .then(this.responseHandler)
        .catch(this.errorHandler);
    } catch (error) {
      // alert("Error");
    }
  };

  /**
   *
   */

  /**
   * Button used when submitting data to the server
   */
  submitButton = (props) => {
    if (this.state.loading === true) {
      return (
        <Button
          {...props}
          endIcon={<CircularProgress color="secondary" size="16px" />}
          disabled
        />
      );
    }
    return (
      <Button
        {...props}
        onClick={() => {
          if (typeof props.onClick === "function") {
            props.onClick();
          }
          this.request();
        }}
      />
    );
  };

  /**
   * Reset Complete state.
   * Remove the form from the completed state
   */
  resetComplete = () => {
    this.setState({ ...this.state, ["completed"]: false });
  };

  /**
   * Pagination if there is any
   */
  paginator = () => {
    return <Paginator this={this} />;
  };

  nextPage = () => {
    if (typeof store.getState().dataStore[this.thread] == "object") {
      let dataStore = JSON.parse(JSON.stringify(store.getState().dataStore));
      // console.clear();
      // console.log("\n\n\n");
      // console.log(dataStore);
      let _page = JSON.parse(JSON.stringify(dataStore[this.thread]));
      let nextPage = _page.current_page + 1;
      let limit = _page.per_page || _page.limit || this.props.limit;

      if (_page.last_page == _page.current_page) {
        return false;
      }

      if (_page.pagesLoaded.indexOf(nextPage) > -1) {
        // console.log("Page loaded");
        return false;
      }

      if (_page.last_page == _page.current_page) {
        // console.log("Page loaded 12");
        return false;
      }

      if (_page.total / _page[this.props.dataKey].length <= 1) {
        console.log(_page.total / _page[this.props.dataKey].length <= 1);
        return false;
      }

      if (this.state.loadingPage == true) {
        return false;
      }

      if (_page.total - _page[this.props.dataKey].length < 0) {
        return false;
      }

      // go to the next page
      this.request({ loadNextPage: true, nextPage });
    }
  };

  /**
   * On Search ..
   * EWhe
   */
  onSearch = (e) => {
    this.setState({ ...this.state, searching: true });
    this.refreshRequest();
  };

  /**
   * Render the application ui
   */
  render() {
    let res = {};
    if (typeof store.getState().dataStore[this.thread] == "object") {
      res = store.getState().dataStore[this.thread];
    } else if (typeof this.props.initialData == "object") {
      res = this.props.initialData;
    } else {
      res = {};
    }
    if (this.props.hasPagination === true) {
      let addOnThread = this.props.addOnThread;
      if (typeof this.props.addOnThread == "undefined") {
        addOnThread = "";
      }
      let thread = this.props.thread + addOnThread;
      console.log(store.getState().dataStore, thread);
      if (Object.keys(store.getState().dataStore).indexOf(thread) > -1) {
        res = store.getState().dataStore[thread];
        console.log("Res: ", res);
      }
    }
    if (this.isHook === true) {
      return {
        some: "text",
      };
    }

    let notificationsEl = document.querySelector("#notifications");
    if (notificationsEl == null) {
      let div = document.createElement("div");
      div.setAttribute("id", "notifications");
      document.body.prepend(div);
    }
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <div>
          <Snackbar
            title="Hello"
            className={this.state.errorType}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={this.state.showMessageSnackBar}
            onClose={() =>
              this.setState({ ...this.state, showMessageSnackBar: false })
            }
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={() =>
                  this.setState({
                    ...this.state,
                    showMessageSnackBar: false,
                  })
                }
              >
                <Close />
              </IconButton>
            }
            message={this.state.errorMessage}
            key="error"
          />
          <div
            className="d-none"
            style={{
              zIndex: 10000000000,
              position: "fixed",
              top: "20px",
              right: "20px",
            }}
          >
            {this.state.showMessageSnackBar == true ? (
              <Alert
                variant="filled"
                severity={this.state.errorSeverity}
                onClose={() => {}}
                closeIcon={<Close />}
              >
                <AlertTitle>{this.state.errorTitle}</AlertTitle>
                {this.state.errorMessage}
              </Alert>
            ) : (
              ""
            )}
          </div>
        </div>
      </ThemeProvider>,
      document.querySelector("#notifications")
    );

    if (typeof this.props.children === "function") {
      return (
        <React.Fragment>
          <Dialog open={this.state.showDialog}>
            <Alert
              onClose={() =>
                this.setState({ ...this.state, showDialog: false })
              }
            >
              <AlertTitle>{this.state.dialogTitle}</AlertTitle>
              {this.state.dialogMessage}
            </Alert>
          </Dialog>
          {this.props.children({
            payload: this.state.payload,
            loading: this.state.loading,
            completed: this.state.completed,
            refreshing: this.state.refreshing,
            resetComplete: this.resetComplete,
            refresh: this.refreshRequest,
            ErrorView: this.errorView,
            RefreshButton: (props) => {
              const classes = useStyles();
              if (props.variant == "icon") {
                return (
                  <IconButton
                    onClick={this.refreshRequest}
                    disabled={this.state.refreshing}
                    className={classes.refreshButton}
                  >
                    {this.state.refreshing == true ? (
                      <div className={classes.refreshButtonReloading}>
                        <CircularProgress color="secondary" size="20px" />
                      </div>
                    ) : (
                      <Refresh />
                    )}
                  </IconButton>
                );
              }

              return (
                <Button
                  {...props}
                  onClick={() => this.refreshRequest()}
                  endIcon={
                    this.state.refreshing ? (
                      <CircularProgress color="secondary" size="20px" />
                    ) : (
                      props.endIcon
                    )
                  }
                />
              );
            },
            data: res,
            res: res,
            searching: this.state.searching,
            resetForm: this.resetForm,
            setPayload: this.setPayload,
            autoloadStarted: this.state.autoloadStarted,
            errorMessage: this.state.errorMessage,
            sorting: this.state.sorting,
            onSort: () => {
              alert("Sorting Not implemented");
            },
            nextPage: this.nextPage,
            prevPage: () => {
              alert("Prev Page not implemented");
            },
            page: this.state.page,
            input: this.input,
            Button: this.submitButton,
            SubmitButton: this.submitButton,
            FileInput: this.fileInput,
            loadNextPage: this.loadNextPage,
            loadingPage: this.state.loadingPage,
            Paginator: this.paginator,
            onSearch: this.onSearch,
            submit: this.request, /// send the data to the server
          })}
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}

ApiRequest.propTypes = {
  dataIndex: propTypes.any, // the position of data in an array the array default is boolean
  dataPayload: propTypes.any, // This is used when in an array or an object
  withFileUpload: propTypes.bool,
  initialData: propTypes.object,
  thread: propTypes.string, // this thread should be created in api threads
  initialPayload: propTypes.object,
  endPoint: propTypes.string,
  autoload: propTypes.bool,
  query: propTypes.object, // object will be convered into a query string
  isForm: propTypes.bool, // make the request become a form
  isQueryInput: propTypes.bool,
  /**
   * Update the data in datastore with newly loaded data
   *
   */
  uiUpdateKey: propTypes.string,
  uiUpdateThread: propTypes.string,
  uiUpdateType: propTypes.string,
  responseKey: propTypes.string,
  customResponse: propTypes.bool,
  // reload on success
  reloadOnSuccess: propTypes.array,

  // callbacks: propTypes.objectOf({
  //   resetFormCallback: propTypes.func,
  //   success: propTypes.func,
  // }),
};

/**
 * Refresh Request Button
 */
export function RefreshRequestButton(props) {
  return (
    <ApiRequest {...props}>
      {({ refresh, RefreshButton }) => {
        return <RefreshButton variant="icon" {...props} />;
      }}
    </ApiRequest>
  );
}

/**
 * Paginator
 */
function Paginator(props) {
  const classes = useStyles();
  const res = useSelector(({ dataStore }) => dataStore[props.this.thread]);
  let total = 0;
  if (typeof res == "object") {
    if (typeof res.data == "object") {
      total = res.last_page;
    }
  }
  return (
    <div
      className={clsx(classes.paginationBtnRoot, "d-flex align-items-center")}
    >
      <IconButton
        onClick={props.this.prevPage}
        className={clsx(classes.paginationBtn)}
      >
        <ArrowBack />
      </IconButton>
      <div
        className={clsx("d-flex justify-content-between align-items-center")}
      >
        <span className={classes.pageIndex}>{total}</span>
        <div className={classes.divider} />
        <span className={classes.pageIndex}>
          {props.this.state.currentPage}
        </span>
      </div>
      <IconButton
        onClick={() => {
          props.this.nextPage();
          console.log(res);
        }}
        className={clsx(classes.paginationBtn)}
      >
        <ArrowForward />
      </IconButton>
    </div>
  );
}
