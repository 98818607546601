import { createSlice, configureStore } from "@reduxjs/toolkit";
import { cacheUserDataOnDevice } from "./cacheUserOnDevice";

const userModule = createSlice({
  name: "users",
  initialState: [
    {
      name: "",
    },
  ],
  reducers: {
    updateUsersList: (state, action) => {
      return { ...state };
    },
  },
});

const apiRequestStateChange = createSlice({
  name: "apiRequest",
  initialState: {
    CHANGE_STATE: false,
  },
  reducers: {
    updateApiRequestState: (state, { action, payload }) => {
      const { thread, data } = payload;
      state[thread] = data;
    },
  },
});

/**
 * Data store
 */
const dataStore = createSlice({
  name: "dataStore",
  initialState: {
    CHANGE_STATE: false,
  },
  reducers: {
    updateStore: (state, { action, payload }) => {
      const { thread, data, hasPagination } = payload;
      if (hasPagination == true) {
        state[thread] = { ...data, ...payload };
      } else {
        state[thread] = data;
      }
    },
  },
});

/**
 * Shopping Cart
 */
let initialCart = window.localStorage.getItem("shopping-cart");
if (typeof initialCart == "string") {
  try {
    initialCart = JSON.parse(initialCart);
    if (typeof initialCart != "object") {
      initialCart = {};
    }
  } catch (err) {
    initialCart = {};
  }
}

const shoppingCartSlice = createSlice({
  name: "cart",
  initialState: {
    CHANGE_STATE: "CHANGE_STATE",
    items: [],
    ...initialCart,
  },
  reducers: {
    addItemToCart: (state, { action, payload }) => {
      state.items = [...state.items, payload];
      state.CHANGE_STATE = Date.now();

      window.localStorage.setItem(
        "shopping-cart",
        JSON.stringify({ CHANGE_STATE: state.CHANGE_STATE, items: state.items })
      );
    },

    removeItemFromCart: (state, { action, payload }) => {
      let items = [...state.items];
      items = items.filter((item) => {
        if (item.productId == payload.productId) {
          return false;
        }
        return true;
      });
      state.CHANGE_STATE = Date.now();
      state.items = items;
      window.localStorage.setItem(
        "shopping-cart",
        JSON.stringify({ CHANGE_STATE: state.CHANGE_STATE, items })
      );
    },

    updateCartItem: (state, { action, payload }) => {
      let items = [...state.items];
      items = items.map((item) => {
        if (item.productId == payload.productId) {
          return payload;
        }
        return item;
      });
      state.CHANGE_STATE = Date.now();
      state.items = items;
      window.localStorage.setItem(
        "shopping-cart",
        JSON.stringify({ CHANGE_STATE: state.CHANGE_STATE, items: items })
      );
    },

    /**
     * Clear the shopping cart
     */
    emptyShoppingCart: (state) => {
      state.CHANGE_STATE = Date.now();
      state.items = [];
      window.localStorage.setItem(
        "shopping-cart",
        JSON.stringify({ CHANGE_STATE: state.CHANGE_STATE, items: [] })
      );
    },
  },
});

export const {
  addItemToCart,
  removeItemFromCart,
  updateCartItem,
  emptyShoppingCart,
} = shoppingCartSlice.actions;

/**
 * Product filters slice
 */
const filters = createSlice({
  name: "productFilters",
  initialState: {
    CHANGE_STATE: "CHANGE_STATE",
    price: {
      min: "100000",
      max: "1000000",
    },
    gender: {
      male: false,
      female: false,
      children: false,
      unisex: false,
    },
    brands: [],
    categories: [],
  },
  reducers: {
    onFilterChange: (state, { action, payload }) => {},

    onBrandFilterChange: (state, { action, payload }) => {
      state.brands = payload;
    },

    onCategoryFilterChange: (state, { action, payload }) => {
      state.categories = payload;
    },

    onPriceFilterChange: (state, { action, payload }) => {
      state.price = payload;
    },

    onGenderFilterChange: (state, { action, payload }) => {
      state.gender = payload;
    },
  },
});

export const {
  onFilterChange,
  onGenderFilterChange,
  onPriceFilterChange,
  onBrandFilterChange,
  onCategoryFilterChange,
} = filters.actions;

/**
 * Authentication
 */
const authSlice = createSlice({
  name: "auth",
  initialState: {
    authenticated: false,
    token: "",
    accountCreationOnGoing: false,
  },
  reducers: {
    loginUser: (state, { payload, action }) => {
      console.log("payload:: ", payload);
      cacheUserDataOnDevice({
        ...payload,
      });
      state.authenticated = true;
      return state;
    },

    logoutUser: (state) => {
      console.log("Loging out user", state);
      state.authenticated = false;
      return state;
    },
    signUpUser: (state) => {
      state.authenticated = true;
      state.accountCreationOnGoing = true;
      return state;
    },

    accountCreationOnGoing: (state) => ({
      ...state,
      accountCreationOnGoing: true,
    }),

    completeSignUp: (state) => ({ ...state, accountCreationOnGoing: false }),
  },
});

export const {
  loginUser,
  logoutUser,
  signUpUser,
  accountCreationOnGoing,
  completeSignUp,
} = authSlice.actions;

export const { updateStore } = dataStore.actions;

/**
 * Api Request state change for monitoring state globerly
 */
export const { updateApiRequestState } = apiRequestStateChange.actions;

/**
 * Store all outgoing requests to the server
 */
const cacheRequestStore = createSlice({
  name: "cacheRequest",
  initialState: {
    CHANGE_STATE: false,
  },
  reducers: {
    cacheRequest: (state, { action, payload }) => {
      const { thread, refresh } = payload;
      state.CHANGE_STATE = new Date().getTime().toString();
      state[thread] = refresh;
    },
  },
});

export const { cacheRequest } = cacheRequestStore.actions;

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    cart: shoppingCartSlice.reducer,
    userModule: userModule.reducer,
    dataStore: dataStore.reducer,
    cacheRequestStore: cacheRequestStore.reducer,
    apiRequestStateChange: dataStore.reducer,
    filters: filters.reducer,
  },
});

export default store;
