import {
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import ViewBlogPost from "../Blog/ViewBlogPost";
import { grey, orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: grey[100],
  },
  header: {
    width: "100%",
    backgroundColor: orange[300],
    backgroundImage: "url('/images/intro-slider/projects.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff!important",
  },
}));

export default function ViewProject(props) {
  const classes = useStyles();
  let title = useParams().project.replaceAll("_", " ");
  let post_id = useParams().project.slice(0, useParams().project.indexOf("-"));
  const history = useHistory();
  title = title.slice(title.indexOf("-") + 1);
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Container className="py-5">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" color="inherit">
                Current Projects
              </Typography>
              <Typography>
                Check out our current projects which we are running. <br /> Each
                project is carried out under our programs.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container>
        {/* <div
          className="py-2 pl-3"
          style={{ width: "100%", borderLeft: "solid 20px #009348" }}
        >
          <Typography variant="h2">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/projects")}
            >
              Projects
            </span>{" "}
            / <small>{title}</small>
          </Typography>
        </div>
        <Divider /> */}
        <Grid container className="">
          <Grid item xs={12}>
            <ViewBlogPost
              post_id={post_id}
              hideNextPrevBtns={true}
              blog_name="CURRENT_PROJECTS"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
