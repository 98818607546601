import { Container, Typography, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { grey, orange, purple, red } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles((theme) => ({
  main: {
    // height: "150px",
    width: "100%",
    backgroundColor: "#fff",
    backgroundImage: "url('/images/our-history-bg.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
  },
  content: {
    backgroundColor: grey[100],
  },
  paper: {
    borderRadius: "10px",
  },
  imgFluid: {
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
}));
export default function OurHistory(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={clsx(classes.main)}>
        <div className="py-5" style={{ backgroundColor: "rgba(0,0,0,0.4)" }}>
          <Container>
            <Typography
              color="inherit"
              variant="h2"
              style={{ color: "#ffff00" }}
            >
              How we started
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <p className="mt-1">
                  <Typography>
                    Abundant Blessing Ministries was started by two women from
                    the US who initially traveled together to help children in
                    Uganda. After six trips to Uganda, ministering to children
                    and community women, they were frustrated with the financial
                    irresponsibility of the ministries that they partnered with.
                  </Typography>
                </p>
              </Grid>
              <Grid item xs={12} md={12}>
                <LazyLoadImage
                  className={classes.imgFluid}
                  src="./images/nancy-and-linda.jpg"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography>
                  During their visits they were able to identify a few young
                  people who loved God and desired to minister to others in an
                  honest, humble, loving and financially responsible way. They
                  joined the four young bright Ugandan Christians and
                  Abundant Blessing Ministries was born.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <LazyLoadImage
                  className={classes.imgFluid}
                  src="./images/clyan-elvis-chris.jpg"
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}
