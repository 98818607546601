import React from "react";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  paper: {
    height: "300px",
    border: "none",
  },
  intro: {
    backgroundColor: "#fefefe",
  },
}));

export default function Archives(props) {
  const classes = useStyles();
  return (
    <div className={clsx("w-100", classes.intro)}>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Paper variant="outlined" className={classes.paper}>
            <Typography>Newsletters</Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
