import { Grid, makeStyles } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import React from "react";
import IntroSlider from "./IntroSlider";
import SectionOne from "./SectionOne";
import SectionThree from "./SectionThree";
import SectionTwo from "./SectionTwo";
import SectionFourOne from "./SectionFourOne";
import MissionVision from "./MissionVision";
import OurHistory from "../OurHistory";
const useStyles = makeStyles((theme) => ({
  root: {
    ["& h5"]: {
      fontSize: "20px !important",
      lineHeight: "40px",
      color: blueGrey[800],
    },
    [theme.breakpoints.down("md")]: {
      ["& .MuiTypography-root"]: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
  },
}));

export default function LandingMain(props) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <IntroSlider />
      </Grid>
      {/* <Grid item xs={12}>
        <Container>
          <Typography variant="h2" className="mt-5 mb-5">
            Recent
          </Typography>
          <RecentActivitiesSlider />
        </Container>
      </Grid>
       */}

      <Grid item xs={12}>
        {/* <SectionFourOne /> */}
        <MissionVision />
        <OurHistory />
        {/* <SectionFive /> */}
      </Grid>
    </Grid>
  );
}
