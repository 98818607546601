import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import { grey, orange, yellow } from "@material-ui/core/colors";
import { ArrowForward } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import Images from "../../images/images";
import programsStyles from "./programsStyles";

const useStyles = makeStyles((theme) => ({
  intro2: {
    backgroundColor: theme.palette.brand.orange,
    backgroundImage: "url('/images/programs/financial-club/intro.jpg')",
    backgroundSize: "100%",
    backgroundPosition: "center",
    color: "#fff",
    minHeight: "400px",
  },
  section: {
    minHeight: "300px",
    backgroundColor: grey[100],
    paddingTop: "50px",
    ["& *"]: {
      color: theme.palette.brand.brown + "!important",
    },
  },
  img: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: "5px",
  },
  paperCard: {
    boxShadow: theme.boxShadows[3],
  },
  cardHeader: {
    width: "100%",
    // height: "150px",
    backgroundSize: "100% auto",
    backgroundPositionY: "top",
  },
  
}));

const projectsList = [];

export default function FinancialClubProgramme(props) {
  const classes1 = programsStyles();
  const classes = useStyles();
  const [activeProjectsList, setActiveProjectsList] = useState(projectsList);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} className={(classes1.intro, classes.intro2)}>
          <Container className="mt-5">
            <Grid container alignItems="center" spacing={5} className="py-5">
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h1"
                  color="inherit"
                  style={{ color: "#fff" }}
                >
                  The Need
                </Typography>
                <Typography
                  className="mt-4"
                  variant="h6"
                  style={{ textAlign: "justify", color: "#fff", lineHeight: "25px" }}
                >
                  Feeling lonely and hopeless during the covid pandemic became a
                  huge issue for many family leaders, mostly women. Schools and
                  stores were forced to close, leaving many people feeling
                  isolated and forgotten. We turned this difficult season into
                  an opportunity to provide community support, practical
                  biblical life teaching and financial hope and training by
                  starting our first financial clubs.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Grid
        container
        className={clsx(classes.section)}
        spacing={3}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Container>
            <Grid container spacing={3} alignItems="center" className="my-3">
              <Grid item xs={12} md={6}>
                <Paper>
                  <ReactPlayer
                    width="100%"
                    style={{ border: "none" }}
                    className={classes.player}
                    url="https://www.youtube.com/watch?v=Fj5mpzarL54"
                    controls={true}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h3"
                  color="inherit"
                  // style={{ color: yellow[700] }}
                >
                  The Goal
                </Typography>
                <Typography
                  style={{
                    lineHeight: "35px",
                    fontWeight: 300,
                    textAlign: "justify",
                  }}
                  paragraph
                  variant="h6"
                >
                  Our goal is to help families find joy through a relationship
                  with Jesus, to develop community and to learn how to better
                  manage their finances, through providing an opportunity to
                  open a savings account. The average Ugandan does not have
                  access to save money through banks because of their low
                  income: saving even small amounts weekly can lead to stress
                  reduction and an increased quality of life.
                </Typography>
              </Grid>

              <Grid item xs={12} md={7} className="my-3 mt-md-4">
                <Typography variant="h3">The Foundation</Typography>
                <Typography
                  style={{
                    lineHeight: "35px",
                    fontWeight: 300,
                    textAlign: "justify",
                  }}
                  paragraph
                  variant="h6"
                >
                  For the first ten weeks we teach practical Biblical Christian
                  life principles that improve their quality of life. Among
                  other topics, we teach about the benefits of forgiveness and
                  the importance of thankfulness, providing members an
                  opportunity to meet and develop significant community
                  relationships.
                </Typography>
                <Typography
                  variant="h6"
                  style={{ lineHeight: "35px", fontWeight: 300 }}
                  paragraph
                >
                  They get into small groups each week and talk about their
                  prayer requests and their challenges. Friendships have formed
                  and many have found joy in the Lord that they never knew
                  before.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5} className="my-3 mt-md-4">
                <LazyLoadImage
                  src={
                    "/images/programs/financial-club/elvis-teaching-foundation.jpg"
                  }
                  className={classes.img}
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          background: "url('/images/landing/intro-end.jpg')",
          backgroundSize: "cover",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: grey[200],
            backdropFilter: "blur(20px)",
          }}
          className="py-5"
        >
          <Container>
            <Grid container spacing={3} alignItems="center" className="my-4">
              <Grid item xs={12} md={12}>
                <Typography variant="h3" color="inherit">
                  The Second Phase
                </Typography>
                <Typography
                  variant="h6"
                  style={{ lineHeight: "35px", fontWeight: 300 }}
                >
                  The second phase of our club starts with teaching about
                  financial responsibility and being a good steward of whatever
                  resources you have been blessed with. Everyone is required to
                  save a minimum of $1.75 a month. Individuals can apply for a
                  loan as long as they have 10% of their loan request in
                  savings. If they qualify, they can receive up to $100 to
                  invest in or start their new business.
                </Typography>
                <LazyLoadImage
                  src={
                    "/images/programs/financial-club/elvis-giving-out-money.jpg"
                  }
                  className="my-3"
                  style={{ width: "100%", height: "auto" }}
                />
                <Typography
                  variant="h6"
                  style={{ lineHeight: "35px", fontWeight: 300 }}
                >
                  Everyone is required to save a minimum of $1.75 a month.
                  Individuals can apply for a loan as long as they have 10% of
                  their loan request in savings. If they qualify, they can
                  receive up to $100 to invest in or start their new business.
                  After paying off their first loan, with timely payments they
                  are eligible to reapply for another loan for a larger amount.
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="mt-5">
              <Grid item xs={12} md={4}>
                {/* <Button variant="outlined" endIcon={<ArrowForward />}>
                  Loan application process example
                </Button> */}
                <Paper className={classes.paperCard}>
                  <div
                    className={classes.cardHeader}
                    style={{
                      backgroundImage:
                        "url('/images/programs/financial-club/chicken.jpg')",
                    }}
                  >
                    <div
                      className={clsx(
                        classes.mask,
                        "p-3 d-flex align-items-center"
                      )}
                    >
                      <Typography style={{ color: "#fff" }} variant="h3">
                        Success story from a financial club Member.
                      </Typography>
                    </div>
                  </div>
                  <div className="p-3">
                    <Typography>
                      My name is Rose, a mother 6 children, I am the bread
                      winner at my house. In April this year teacher Bernah
                      shared a story during our
                    </Typography>
                  </div>
                  <div>
                    <Button fullWidth endIcon={<ArrowForward />}>
                      Read More
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      {/* <Grid
        container
        style={{
          background: "url('/images/landing/intro-end.jpg')",
          backgroundSize: "cover",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            // backgroundColor: grey[200],
            backdropFilter: "blur(20px)",
          }}
          className="py-5"
        >
          <Container>
            <Grid
              container
              spacing={3}
              direction="row-reverse"
              alignItems="center"
            >
              <Grid item xs={12} md={6}>
                <LazyLoadImage
                  src="/images/programs/financial-club/7.jpg"
                  className={classes.img}
                />
              </Grid>
              <Grid item xs={12} md={6} className="py-5">
                <Typography
                  variant="h6"
                  style={{
                    lineHeight: "35px",
                    fontWeight: "300",
                    color: "#fff",
                  }}
                >
                  Every few months the club celebrates any group who has every
                  member keep their commitment. Around Christmas time each faith
                  group member will receive a chicken to celebrate the holidays,
                  paid for by the club.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Container>
        <Grid item xs={12} md={6} className={classes.videoImage}></Grid>
        <Grid item xs={12} md={6} className="py-5 my-md-5" align="center">
          <Typography
            variant="h6"
            style={{
              lineHeight: "35px",
              fontWeight: "300",
            }}
          >
            We have just started our second financial club and we are using the
            first one as a model to try to follow. Over time we plan to have a
            number of these clubs starting in different villages. After our
            initial investment of a few thousand dollars the clubs should be
            self-sustaining and will only give out money to new clients when the
            existing members make their loan payments.
          </Typography>
        </Grid>
      </Container>
    */}
    </React.Fragment>
  );
}
