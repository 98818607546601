import {
  Checkbox,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  Facebook,
  LocationCity,
  MailOutline,
  PhoneAndroid,
  Twitter,
  YouTube,
} from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ApiRequest from "../../../api/ApiRequest";
import { SUBSCRIBE_TO_NEWSLETTER } from "../../../api/apiThreads";
import ContactMain from "../../../site/pages/Contact/ContactMain";
import { KijjeInput } from "../inputs/Inputs";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(7),
    // minHeight: "400px",
    // background: "rgb(34,193,195)",
    // background: "linear-gradient(43deg, rgba(34,193,195,1) 0%, rgba(253,187,45,0.8802871490393032) 100%)",
    background: "rgb(16,99,142)",
    background:
      "-moz-linear-gradient(43deg, rgba(16,99,142,1) 0%, rgba(32,213,43,0.8606793059020483) 100%)",
    background:
      "-webkit-linear-gradient(43deg, rgba(16,99,142,1) 0%, rgba(32,213,43,0.8606793059020483) 100%)",
    background:
      "linear-gradient(43deg, rgba(16,99,142,1) 0%, rgba(32,213,43,0.8606793059020483) 100%)",
    filter:
      'progid:DXImageTransform.Microsoft.gradient(startColorstr="#10638e",endColorstr="#20d52b",GradientType=1)',

    background: theme.palette.primary.main,

    paddingTop: theme.spacing(6),
    color: theme.palette.primary.contrastText,
    paddingBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  container: {
    [theme.breakpoints.up("md")]: {
      width: "1125px",
      margin: "0 auto",
    },
  },
  title: {
    fontSize: "20px",
    color: theme.palette.primary.contrastText,
    lineHeight: "30px",
  },
  links: {
    // ...theme.typography.body1,
    color: theme.palette.primary.contrastText,
    listStyle: "none",
    padding: "0px",
    opacity: "0.8",
    cursor: "pointer",
    paddingTop: "20px",
    ["& *"]: {
      fontSize: "12px",
      lineHeight: "22px",
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.contrastText,
    // boxShadow:
    // "0 0 20px -4px rgba(200,81,165,.28), 0 6px 20px 6px rgba(0,176,202,.28)",
  },
  paper: {
    padding: theme.spacing(3),
    boxShadow:
      "0 4px 20px -4px rgba(200,81,165,.28), 0 6px 20px 6px rgba(0,176,202,.28)",
  },
  flexColumn: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  contactUs: {
    width: "100%",
    background: "url('/images/landing/vision.jpg')",
  },
  contactUsBg: {
    backgroundColor: "hsla(139, 2%, 100%, 0.5)",
    backdropFilter: "blur(20px)",
    paddingBottom: "70px",
  },
  smallFab: {
    width: "35px",
    boxShadow: "none",
    height: "30px",
    padding: "10px",
    fontSize: "1rem",
    ["& *"]: {
      fontSize: "18px",
      lineHeight: "22px",
    },
  },
}));

export default function SiteFooter(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <ContactMain />

      <footer className={classes.root}>
        <Container maxWidth="lg" justify="center">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={8}>
              <Typography
                variant="h2"
                className="mb-3 mt-2"
                style={{ color: "#fff" }}
              >
                {/* ABUNDANT BLESSING MINISTRIES */}
                Abundant Blessing Ministries
              </Typography>
              <Divider className={classes.divider} />
              <Grid container spacing={3} className="mt-3">
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <List dense disablePadding>
                    <ListItem>
                      <ListItemIcon>
                        <Fab className={classes.smallFab}>
                          <MailOutline />
                        </Fab>
                      </ListItemIcon>
                      <ListItemText>
                        info@abundantblessinguganda.com
                      </ListItemText>
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <Fab className={classes.smallFab}>
                          <PhoneAndroid />
                        </Fab>
                      </ListItemIcon>
                      <ListItemText>
                        +256-704543419 | +256-784989470
                      </ListItemText>
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <Fab className={classes.smallFab}>
                          <LocationCity />
                        </Fab>
                      </ListItemIcon>
                      <ListItemText>
                        Kaziba Village Namayumba sub-county Wakiso District
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Paper className={classes.paper}>
                <ApiRequest thread={SUBSCRIBE_TO_NEWSLETTER}>
                  {({ res, SubmitButton, input }) => {
                    if (typeof res == "object") {
                      if (typeof res.data == "object") {
                        if (res.data.subscribed == true) {
                          return (
                            <div align="center">
                              <LazyLoadImage
                                style={{ height: "150px", width: "auto" }}
                                src="./images/subscribed.png"
                              />
                              <Typography>
                                Thank you for subscribing to our newsletter and
                                for being part of Abundant Blessing Ministries.
                              </Typography>
                            </div>
                          );
                        }
                      }
                    }

                    return (
                      <React.Fragment>
                        <Typography
                          variant="h4"
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "600",
                          }}
                        >
                          Stay in the know. Subscribe to our newsletter.
                        </Typography>
                        <KijjeInput
                          {...input({ name: "email" })}
                          variant="filled"
                          placeholder="Email*"
                        />
                        <Typography
                          style={{
                            fontSize: "12px",
                            lineHeight: "16px",
                            fontWeight: "400",
                          }}
                        >
                          <Checkbox size="small" /> I'd like to receive updates
                          about Abundant Blessing Ministries.
                        </Typography>
                        <SubmitButton
                          variant="contained"
                          color="secondary"
                          className="mt-3"
                        >
                          Subscribe
                        </SubmitButton>
                      </React.Fragment>
                    );
                  }}
                </ApiRequest>
              </Paper>
            </Grid>
          </Grid>
          <Grid container justify="center" className="mt-5">
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid
              item
              xs={12}
              className={clsx(
                "d-flex justify-content-between pt-3 align-items-center",
                classes.flexColumn
              )}
            >
              <Typography style={{ color: "#222", fontWeight: 600 }}>
                <small>
                  &copy;{new Date().getFullYear()} All rights reserved
                  {" ABUNDANT BLESSING MINISTRIES".toLocaleUpperCase()}
                </small>
              </Typography>
              <div>
                <IconButton
                  component="a"
                  href="https://www.facebook.com/AbundantBlessingMinistriesUganda/"
                >
                  <Facebook />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://www.youtube.com/channel/UClp-zIbm2urdYqSdwc6HlQw"
                >
                  <YouTube />
                </IconButton>
                <IconButton>
                  <Twitter />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </React.Fragment>
  );
}
