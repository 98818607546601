import {
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import ViewBlogPost from "../Blog/ViewBlogPost";
import { brown, grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: grey[100],
  },
  header: {
    backgroundColor: brown[200],
    backgroundImage: "url('/images/intro-slider/programs.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff!important",
  },
}));

export default function Program(props) {
  const classes = useStyles();
  let title = useParams().program.replaceAll("_", " ");
  let post_id = useParams().program.slice(0, useParams().program.indexOf("-"));
  const history = useHistory();
  title = title.slice(title.indexOf("-") + 1);
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <Container>
            <Grid
              container
              spacing={3}
              alignItems="center"
              className="pt-5 pb-3"
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h6" color="inherit">
                  Programs
                </Typography>
                <Typography className="pt-2 pb-5 " variant="h3" color="inherit">
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Container className="py-5">
        <Grid container className="py-4" justifyContent="center">
          <Grid item xs={12} md={10}>
            {/* <Paper className="p-3" elevation="0"> */}
            <ViewBlogPost
              hidePostTitle
              post_id={post_id}
              hideNextPrevBtns={true}
              blog_name="PROGRAMS"
            />
            {/* </Paper> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
