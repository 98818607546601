import {
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles((theme) => ({
  root: {
    background: grey[100],
  },
  paper: {
    borderRadius: "10px",
    boxShadow: theme.boxShadows[3],
    height: "100%",
  },
  cardHeader: {
    height: "100%",
    width: "100%",
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "left",
    borderRadius: "10px",
    maxHeight: "250px",
  },
}));
export default function MissionVision(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Container className="py-3 py-md-5">
          <Grid container spacing={3} className="my-md-3">
            <Grid item xs={12} align="center">
              <Typography variant="h3" className="mb-2 mb-md-3">
                Who We Are
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <div className={classes.cardHeader}>
                      <LazyLoadImage
                        src={"/images/landing/mission.jpg"}
                        className={classes.image}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <div className={clsx(classes.cardContent, "p-3")}>
                      <Typography color="inherit" variant="h3">
                        Our Mission
                      </Typography>
                      <Typography>
                        <Typography color="inherit" className="mt-md-3">
                          To bring the Ugandan people into a saving relationship
                          with Jesus Christ. This will be done by proclaiming
                          the gospel as we strive to meet physical, spiritual
                          and emotional needs, with humility, in the name of
                          Jesus.
                        </Typography>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <div className={classes.cardHeader}>
                      <Hidden mdUp>
                        <LazyLoadImage
                          src="/images/landing/vision-image.jpg"
                          className={classes.image}
                        />
                      </Hidden>
                      <Hidden smDown>
                        <LazyLoadImage
                          src="/images/christina-teachings-and-principles-resized.jpg"
                          className={classes.image}
                        />
                      </Hidden>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <div className={clsx(classes.cardContent, "p-3")}>
                      <Typography color="inherit" variant="h3">
                        Our Vision
                      </Typography>
                      <Typography color="inherit" className="mt-md-3">
                        To use Christian teaching and principles to improve the
                        quality of life for marginalized people of Uganda. We
                        will strive to look for opportunities to provide for
                        physical needs and to use informal education to teach
                        Christian principles and life skills to strengthen
                        children, families and communities.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
