import { makeStyles } from "@material-ui/core";
import { blue, orange, red, yellow } from "@material-ui/core/colors";

const programsStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  intro: {
    minHeight: "200px",
    backgroundColor: blue[100],
    backgroundSize: "50% auto",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat"

  },
  cardBgImage: {
    backgroundImage: "url('/images/intro-slider/2.jpg')",
    height: "150px",
  },
  cardHeader: {
    padding: "20px",
    height: "100%",
    width: "100%",
    background: "hsla(68, 100%, 10%, 0.7)",
    display: "flex",
    alignItems: "flex-end",
    ["& *"]: {
      color: "#fff!important",
    },
  },
  cardBody: {
    padding: "20px",
    height: "120px",
  },
  cardFooter: {
    padding: "20px",
  },
  player: {
    width: "100%",
    height: "300px",
    background: red[400],
  },
}));


export default programsStyles;
