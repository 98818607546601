import React from "react";
import { Route, Switch } from "react-router-dom";
import Program from "./Program";
import ProgramsMain from "./ProgramsMain";

export default function Programs(props) {
  return (
    <Switch>
      <Route
        path="/programs/:program"
        render={(props) => <Program {...props} />}
      />
      <Route>
        <ProgramsMain />
      </Route>
    </Switch>
  );
}
