import React from "react";
import { Route, Switch } from "react-router-dom";
import ViewBlogPost from "./ViewBlogPost";
import BlogMain from "./BlogMain";

export default function Blog() {
  return (
    <Switch>
      <Route path="/updates/:post_id">
        <ViewBlogPost />
      </Route>
      <Route path="/updates" render={(props) => <BlogMain {...props} />} />
    </Switch>
  );
}
