import { Button, Grid, Hidden, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
/** Styles */
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import Images from "../../../site/images/images";
import appLinks from "../../../site/routes/appLinks";
import SiteDrawer from "./SiteDrawer";

/**Coustom styles */
const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.appBar,
  },

  logo: {
    margin: "20px 0px",
    height: "70px",
    width: "auto",
    [theme.breakpoints.down("md")]: {
      height: "50px",
    },
  },
  heading: {
    fontSize: "30px",
    lineHeight: "45px",
    letterSpacing: "1px",
    marginTop: "15px",
    marginBottom: "15px",
    fontFamily: "'Dancing Script', cursive",

    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      letterSpacing: "4px",
      marginTop: "10px",
      marginBottom: "10px",
      lineHeight: "40px",
    },
  },

  /**
   * Links na componnet
   */

  linksNavRoot: {
    // backgroundColor: "#DB9DB !important",
    backgroundColor: green[100],
    // backgroundColor: theme.palette.brand.orange, //"#DBF9DB !important",
    width: "100%",
    margin: "0px",
    padding: "0px 20px",
    // justifyContent: "space-evenly",

    ["& li"]: {
      padding: "0px 2px 0px 2px",
      // backgroundColor: "red",
    },
    ["& a"]: {
      // color: theme.palette.brand.green,
      textDecoration: "none",
      fontWeight: "400",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      backgroundColor: theme.palette.brand.green,
      color: "#fff!important",
      ["& *"]: {
        color: "#fff!important",
      },
    },
  },
  navLink: {
    margin: "0px 10px",
    position: "relative",
    letterSpacing: "0.5px",
    textAlign: "center!important",
    height: "50px",
    padding: "0px 8px",
    // color: "#fff",
    transition: "0.3s ease",
    position: "relative",

    ["&:hover"]: {
      transition: "0.3s ease",
      // transform: "translate(0px, -8px)",

      // borderBottom: "1px dashed #ffff00",
      borderSpacing: "20px",
      paddingBottom: "7px",
      // backgroundColor: orange[100],
    },
    ["& .drop-down-menu"]: {
      minWidth: "150px",
      padding: "20px",
      minHeight: "200px",
      backgroundColor: theme.palette.primary.light,
      borderRadius: "10px",
      marginTop: "10px",
      boxShadow: theme.boxShadows[3],
      position: "absolute",
      top: "15px",
      zIndex: theme.zIndex.appBar,
      display: "none",
    },
    ["&:hover .drop-down-menu"]: {
      display: "block",
    },
    [theme.breakpoints.down("md")]: {
      backgroundColor: "transparent!important",
      borderTop: "solid 1px #fff",
      borderBottom: "solid 1px #fff",
    },
  },
  activeLink: {
    transition: "0.3s ease",
    // transform: "translate(0px, -8px)",

    backgroundColor: theme.palette.primary.main,
    // borderTopLeftRadius: "20px",
    // borderTopRightRadius: "20px",
    // borderBottom: "1px dashed #ffff00",
    borderSpacing: "20px",
    paddingBottom: "7px",
    color: "#fff",
  },
  appbarSmallScreen: {
    backgroundColor: theme.palette.brand.green + "!important",
    width: "100%",
    color: "#fff!important",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: "22px",
  },
  container: {
    [theme.breakpoints.down("md")]: {
      padding: "0px!important",
      margin: "0px!important",
    },
  },
  test1: {},
  menu: {
    borderTop: "solid 1px " + theme.palette.primary.main,
    padding: "15px",
    boxShadow: theme.boxShadows[3],
    position: "absolute",
    backgroundColor: "#fff",
    zIndex: 29,
    top: 0,
    minWidth: "200px",
    borderRadius: "5px",
    top: "45px",
    left: 0,
    display: "none",
  },
  menuRoot: {
    ["&:hover .menu"]: {
      display: "block",
    },
  },
}));

const RenderNavLink = (props) => {
  let { subLinks, name, path } = props;
  const classes = useStyles();
  if (path == "/") {
    return (
      <Button
        component={NavLink}
        exact
        className={clsx(classes.navLink, "d-flex align-items-center")}
        to={path}
        activeClassName={clsx(classes.activeLink, "active")}
      >
        {name}
      </Button>
    );
  }
  if (Array.isArray(subLinks)) {
    return (
      <Button
        component={NavLink}
        className={clsx(
          classes.navLink,
          "d-flex align-items-center",
          classes.menuRoot
        )}
        to={path}
        activeClassName={clsx(classes.activeLink, "active")}
        endIcon={<KeyboardArrowDown />}
      >
        {name}
        <div className={clsx(classes.menu, "menu")}>
          {subLinks.map((item, index) => {
            let {subLinks} = item;
            if (Array.isArray(subLinks)) {
              return (
                <div>
                  <Button
                    className="d-flex justify-content-start"
                    key={index}
                    component={NavLink}
                    to={item.path}
                  >
                    {item.name}
                  </Button>
                  <div className="px-2">
                    {subLinks.map((item, index) => {
                      return (
                        <Button
                          startIcon={<KeyboardArrowRight />}
                          style={{display: "block", minWidth: "200px"}}
                          className="d-flex justify-content-start"
                          key={index}
                          component={NavLink}
                          to={item.path}
                        >
                          {item.name}
                        </Button>
                      );
                    })}
                  </div>
                </div>
              );
            }
            return (
              <Button
                className="d-flex justify-content-start"
                key={index}
                component={NavLink}
                to={item.path}
              >
                {item.name}
              </Button>
            );
          })}
        </div>
      </Button>
    );
  }
  return (
    <Button
      component={NavLink}
      className={clsx(classes.navLink, "d-flex align-items-center")}
      to={path}
      activeClassName={clsx(classes.activeLink, "active")}
    >
      {name}
    </Button>
  );
};

const TopNav = (props) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.topNavRoot,
        "d-flex flex-column align-items-center justify-content-center w-100"
      )}
    >
      <Images.Logo className={classes.logo} />
      {/* <Typography variant="h1" className={classes.heading}>
        Blessed To Be A Blessing
      </Typography> */}
    </div>
  );
};

const LinksNav = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Hidden mdDown>
        <div
          className={clsx(
            classes.linksNavRoot,
            "d-flex justify-content-center align-items-center flex-wrap"
          )}
        >
          {appLinks.modules.map((link, index) => {
            return <RenderNavLink {...link} key={index} />;
          })}
        </div>
      </Hidden>
      <Hidden lgUp implementation="js">
        <div
          className={clsx(
            classes.appbarSmallScreen,
            "w-100 d-flex align-items-center justify-content-between"
          )}
        >
          <Typography>MENU</Typography>
          <SiteDrawer />
        </div>
      </Hidden>
    </React.Fragment>
  );
};

function SiteAppbar(props) {
  const classes = useStyles();
  const { pathname } = useHistory().location;
  const routeM = useRouteMatch();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <TopNav />
      </Grid>
      <Grid
        item
        xs={12}
        className={clsx(
          classes.primaryNavRoot,
          appLinks.modules.map((link, index) =>
            pathname === link.path ? classes["tab" + index.toString()] : ""
          ),
          "primary-nav"
        )}
      >
        <LinksNav />
      </Grid>
    </Grid>
  );
}

export default SiteAppbar;
