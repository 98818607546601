import React from "react";
/**Material Ui Components */
import { ThemeProvider } from "@material-ui/core/styles";
import "animate.css";
// import { Paper, Container, makeStyles } from "@material-ui/core";
/**Style sheets */
import "bootstrap/dist/css/bootstrap-grid.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "../api/store";
import PageNotFound from "../global/components/error_pages/PageNotFound";
import ScrollToTop from "../global/components/navigation/ScrollToTop";
import SiteAppbar from "../global/components/navigation/SiteAppbar";
import SiteFooter from "../global/components/navigation/SiteFooter";
import MainWrapper from "../global/components/warppers/MainWrapper";
// /**Font faces */
// import "typeface-roboto";
import "../scss/app.scss";
import Landing from "./pages/Landing";
import appLinks from "./routes/appLinks";
/** import theme */
import theme from "./theme/app-theme";


function SiteRouting(props) {

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop>
            <MainWrapper appbar={<SiteAppbar />}>
              <Switch>
                {appLinks.modules.map((Module, index) => {
                  if (index == 0) {
                    return "";
                  }
                  
                    return (
                      <Route
                        path={Module.path}
                        key={index}
                        component={(props) => (
                          <Module.Component module={Module} />
                        )}
                      />
                    );
                })}

                <Route path="/" render={(props) => <Landing />} />
                <Route>
                  <PageNotFound />
                </Route>
              </Switch>
            </MainWrapper>
            <SiteFooter />
          </ScrollToTop>
        </Router>
      </ThemeProvider>
    </ReduxProvider>
  );
}
export default SiteRouting;
